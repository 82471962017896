import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardContent, CardHeader, Divider, Grid, TextField } from "@mui/material";
// import { useForm, SubmitHandler } from "react-hook-form";
// import isURL from "validator/lib/isURL";
// import isNumeric from "validator/lib/isNumeric";
// import isAlphanumeric from "validator/lib/isAlphanumeric";

// import FormTextField from "../form-text-field";
import { definitions } from "../../types/supabase";
// import ButtonLoading from "../../components/button-loading";

// import { useUpdateProfile } from "./use-update-profile";

interface Props {
  currentUser: definitions["users"];
}
// interface FormItems {
//   organizer: string;
//   www: string;
//   phone: string;
// }

const ProfileDetails = ({ currentUser }: Props) => {
  const { t } = useTranslation();
  const { email, username } = currentUser;

  // const { updateProfile, isLoading } = useUpdateProfile();

  // const {
  //   handleSubmit,
  //   formState: { isValid },
  //   control,
  // } = useForm<FormItems>({
  //   mode: "onChange",
  //   defaultValues: {
  //     organizer: organizer || "",
  //     www: www || "",
  //     phone: phone || "",
  //   },
  // });

  // const onSubmit: SubmitHandler<FormItems> = (data) => {
  //   console.log(data);
  //   updateProfile({ userId: id, ...data });
  // };

  return (
    <>
      <Card sx={{ mb: 4 }}>
        <CardHeader title={t("profile.my-account")} />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                label={t("profile.username")}
                name="username"
                value={username}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth disabled label={t("profile.email")} name="email" value={email} variant="outlined" />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {/* {isOrganizer && (
        <Card>
          <CardHeader subheader={t("profile.organizer-subheader")} title={t("profile.organizer-information")} />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormTextField
                  name="organizer"
                  control={control}
                  label={t("profile.organizer-name")}
                  placeholder={t("profile.organizer-name")}
                  rules={{
                    validate: (value: string) =>
                      isAlphanumeric(value, "pl-PL", { ignore: " -" }) ||
                      !value ||
                      t("profile.error-organizer-pattern"),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled
                  label={t("profile.email")}
                  name="email"
                  value={email}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextField
                  name="www"
                  control={control}
                  label={t("profile.www")}
                  placeholder={t("profile.www")}
                  rules={{
                    require: false,
                    validate: (value: string) =>
                      isURL(value, {
                        protocols: ["http", "https"],
                        require_protocol: true,
                      }) ||
                      !value ||
                      t("profile.error-www-pattern"),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextField
                  name="phone"
                  control={control}
                  label={t("profile.phone")}
                  placeholder={t("profile.phone")}
                  rules={{
                    validate: (value: string) =>
                      isNumeric(value, { no_symbols: true }) || !value || t("profile.error-phone-pattern"),
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              p: 2,
            }}
          >
            <ButtonLoading
              size="large"
              variant="contained"
              color="primary"
              type="submit"
              disabled={!isValid || isLoading}
              loading={isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              {t("save")}
            </ButtonLoading>
          </Box>
        </Card>
      )} */}
    </>
  );
};

export default ProfileDetails;
