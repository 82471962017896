import React, { ReactChild } from "react";
import { useSlate } from "slate-react";
// import isURL from "is-url";
import styled from "styled-components";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ToggleButton, { ToggleButtonProps } from "@mui/material/ToggleButton";
import { Editor, Transforms, Element as SlateElement, CustomElement } from "slate";

// import { isLinkActive, getLink, wrapLink, unwrapLink } from "./transforms";
import { MarksFormat, BlockFormat } from "./types";

const ToolbarWrapper = styled.div`
  display: grid;
  border-bottom: solid 1px ${({ theme }) => theme.palette.background.default};
  grid-template-columns: repeat(auto-fit, 48px);
  gap: 8px;
  position: relative;
  padding-bottom: 16px;
`;

const LIST_TYPES = ["unordered-list", "ordered-list"];
// const LINK_INPUT_ID = "link-input";

const isBlockActive = (editor: Editor, format: BlockFormat) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  });

  return !!match;
};

const isMarkActive = (editor: Editor, format: MarksFormat) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const toggleBlock = (editor: Editor, format: BlockFormat) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes((!Editor.isEditor(n) && SlateElement.isElement(n) && (n.type as string)) || ""),
    split: true,
  });

  const newProperties: Partial<CustomElement> = {
    type: isActive ? "paragraph" : isList ? "list-item" : format,
  };

  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor: Editor, format: MarksFormat) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

interface MarkButtonProps extends ToggleButtonProps {
  value: MarksFormat;
  children: ReactChild;
}

interface BlockButtonProps extends ToggleButtonProps {
  value: BlockFormat;
  children: ReactChild;
}

const BlockButton = ({ value, children, ...props }: BlockButtonProps) => {
  const editor = useSlate();
  return (
    <ToggleButton
      {...props}
      value={value}
      selected={isBlockActive(editor, value)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, value);
      }}
      style={{ lineHeight: 1 }}
    >
      {children}
    </ToggleButton>
  );
};

const MarkButton = ({ value, children, ...props }: MarkButtonProps) => {
  const editor = useSlate();
  return (
    <ToggleButton
      {...props}
      value={value}
      selected={isMarkActive(editor, value)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, value);
      }}
      style={{ lineHeight: 1 }}
    >
      {children}
    </ToggleButton>
  );
};

interface ToolbarProps {
  disabled: boolean;
}

function Toolbar({ disabled }: ToolbarProps): JSX.Element {
  return (
    <ToolbarWrapper>
      <MarkButton disabled={disabled} value={MarksFormat.BOLD}>
        <FormatBoldIcon />
      </MarkButton>
      <MarkButton disabled={disabled} value={MarksFormat.ITALIC}>
        <FormatItalicIcon />
      </MarkButton>
      <MarkButton disabled={disabled} value={MarksFormat.UNDERLINE}>
        <FormatUnderlinedIcon />
      </MarkButton>
      <BlockButton disabled={disabled} value={BlockFormat.H1}>
        <LooksOneIcon />
      </BlockButton>
      <BlockButton disabled={disabled} value={BlockFormat.H2}>
        <LooksTwoIcon />
      </BlockButton>
      <BlockButton disabled={disabled} value={BlockFormat.OL}>
        <FormatListNumberedIcon />
      </BlockButton>
      <BlockButton disabled={disabled} value={BlockFormat.UL}>
        <FormatListBulletedIcon />
      </BlockButton>
    </ToolbarWrapper>
  );
}

export default Toolbar;
