export default {
  fontFalimy: "Libre Franklin, sans-serif",
  fontWeight: 400,
  color: "red",
  h1: {
    // fontFamily: "Nunito, sans-serif",
    fontWeight: 700,
    // fontSize: "40px",
  },
  h2: {
    // fontFamily: "Nunito, sans-serif",
    // fontSize: "35px",
  },
  h3: {
    // fontFamily: "Nunito, sans-serif",
    // fontSize: 28,
  },
  h4: {
    // fontFamily: "Nunito, sans-serif",
    // fontSize: 22,
  },
  h5: {
    // fontFamily: "Nunito, sans-serif",
    // fontSize: "18px",
  },
  h6: {
    // fontFamily: "Nunito, sans-serif",
    // fontSize: 16,
  },
  subtitle1: {
    // fontFamily: "Nunito, sans-serif",
  },
  subtitle2: {
    // fontFamily: "Nunito, sans-serif",
  },
  body1: {
    // fontFamily: "Nunito, sans-serif",
  },
  body2: {
    // fontFamily: "Nunito, sans-serif",
  },
  button: {
    // fontFamily: "Nunito, sans-serif",
  },
  caption: {
    // fontFamily: "Nunito, sans-serif",
  },
  overline: {
    // fontFamily: "Nunito, sans-serif",
    fontWeight: 500,
  },
};
