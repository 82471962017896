import React from "react";
import { Button, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const OuterWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
}));

const Wrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100%",
  justifyContent: "center",
}));

const Dashboard = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <OuterWrapper>
      <Wrapper>
        {/* <Typography variant="body1" sx={{ mb: 6 }}>
          {t("dashboard.add-new-event")}
        </Typography> */}
        <Typography variant="h4" sx={{ mb: 4, mx: 4 }}>
          {t("dashboard.add-new-event")}
        </Typography>
        <Button
          component={Link}
          to={"/dashboard/moje-wydarzenia/dodaj/agility"}
          variant="contained"
          sx={{ width: "100%", mb: 2 }}
        >
          {t("agility")}
        </Button>
        <Button
          component={Link}
          to={"/dashboard/moje-wydarzenia/dodaj/obedience"}
          variant="contained"
          sx={{ width: "100%", mb: 2 }}
        >
          {t("obedience")}
        </Button>
        <Button
          component={Link}
          to={"/dashboard/moje-wydarzenia/dodaj/dogfrisbee"}
          variant="contained"
          sx={{ width: "100%", mb: 2 }}
        >
          {t("dogfrisbee")}
        </Button>
        <Button
          component={Link}
          to={"/dashboard/moje-wydarzenia/dodaj/puller"}
          variant="contained"
          sx={{ width: "100%", mb: 2 }}
        >
          {t("puller")}
        </Button>
        {/* <Button component={Link} to={"moje-wydarzenia/dodaj/exam"} variant="contained" sx={{ width: "100%", mb: 2 }} disabled>
          {t("exam", { count: 1 })}
        </Button>
        <Button
          component={Link}
          to={"moje-wydarzenia/dodaj/exhibition"}
          variant="contained"
          sx={{ width: "100%", mb: 2 }}
          disabled
        >
          {t("exhibition", { count: 1 })}
        </Button> */}
      </Wrapper>
    </OuterWrapper>
  );
};

export default Dashboard;
