import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <Container sx={{ py: 4 }}>
      <Typography gutterBottom variant="h3">
        {t("privacy-policy.heading")}
      </Typography>
      <Typography gutterBottom variant="h5">
        {t("privacy-policy.title-1")}
      </Typography>
      <Typography gutterBottom variant="body1">
        {t("privacy-policy.description-1")}
      </Typography>
      <Typography gutterBottom variant="h5">
        {t("privacy-policy.title-2")}
      </Typography>
      <Typography gutterBottom variant="body1">
        {t("privacy-policy.description-2")}
      </Typography>
      <Typography gutterBottom variant="h5">
        {t("privacy-policy.title-3")}
      </Typography>
      <Typography gutterBottom variant="body1">
        {t("privacy-policy.description-3")}
      </Typography>
      <Typography gutterBottom variant="h5">
        {t("privacy-policy.title-4")}
      </Typography>
      <Typography gutterBottom variant="body1">
        {t("privacy-policy.description-4")}
      </Typography>
      <Typography gutterBottom variant="h5">
        {t("privacy-policy.title-5")}
      </Typography>
      <Typography gutterBottom variant="body1">
        {t("privacy-policy.description-5")}
      </Typography>
      <Typography gutterBottom variant="h5">
        {t("privacy-policy.title-6")}
      </Typography>
      <Typography gutterBottom variant="body1">
        {t("privacy-policy.description-6")}
      </Typography>
      <Typography gutterBottom variant="h5">
        {t("privacy-policy.title-7")}
      </Typography>
      <Typography gutterBottom variant="body1">
        {t("privacy-policy.description-7")}
      </Typography>
      <Typography gutterBottom variant="h5">
        {t("privacy-policy.title-8")}
      </Typography>
      <Typography gutterBottom variant="body1">
        {t("privacy-policy.description-8")}
      </Typography>
      <Typography gutterBottom variant="h5">
        {t("privacy-policy.title-9")}
      </Typography>
      <Typography gutterBottom variant="body1">
        {t("privacy-policy.description-9")}
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;
