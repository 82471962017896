import { CustomEditor, Transforms } from "slate";
import { deserializeFromHtml } from "../deserialize";

export const withHtml = (editor: CustomEditor): CustomEditor => {
  const { insertData, isInline, isVoid } = editor;

  editor.isInline = (element) => {
    return element.type === "link" ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === "image" ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    const html = data.getData("text/html");
    console.log({ html });
    if (html) {
      const fragment = deserializeFromHtml(html);
      Transforms.insertFragment(editor, fragment);
      return;
    }

    insertData(data);
  };

  return editor;
};
