import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router";

import EventForm from "../../components/event-form";
import { definitions } from "../../types/supabase";
import { useAddEvent } from "./use-add-event";

interface OnSaveParams {
  event: definitions["events"];
  image: File | null;
}

const MyEventsAdd = (): JSX.Element => {
  const { t } = useTranslation();
  const { addEvent, isLoading } = useAddEvent();
  const { category } = useParams();

  const onSave = ({ event, image }: OnSaveParams) => {
    addEvent({ event, image });
  };

  if (!category || !["agility", "obedience", "dogfrisbee", "puller"].includes(category))
    return <Navigate replace to="/404" />;

  return (
    <>
      <Helmet>
        <title>{t("page-title.my-events-add")}</title>
      </Helmet>
      <EventForm onSave={onSave} loading={isLoading} />
    </>
  );
};

export default MyEventsAdd;
