import { useQuery } from "react-query";

import supabase from "../../supabase";
import { definitions } from "../../types/supabase";

interface Params {
  ownerId?: string;
}

const getOrganizationSupabase = async ({ ownerId }: Params) => {
  if (!ownerId) return null;
  const { data, error } = await supabase
    .from<definitions["organizations"]>("organizations")
    .select("id, name")
    .eq("ownerId", ownerId)
    .single();

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export function useOrganization({ ownerId }: Params) {
  const { isLoading, data, error } = useQuery<definitions["organizations"] | null, Error>(
    ["organizer", ownerId],
    () => getOrganizationSupabase({ ownerId }),
    {
      onSuccess: () => {},
    },
  );

  return { organization: data, isLoading, error };
}
