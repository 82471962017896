import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import supabase from "../supabase";
import { useToast } from "../components/toast";

import { route } from "../routes";

interface AuthParams {
  email: string;
  password: string;
}

const loginSupabase = async ({ email, password }: AuthParams) => {
  const { data, error } = await supabase.auth.signIn({
    email,
    password,
  });

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export function useLogin() {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { t } = useTranslation();

  const { mutate, isLoading, data, error } = useMutation((params: AuthParams) => loginSupabase(params), {
    onError: (error: Error) => {
      showToast(t("error-login"), "error");
      showToast(error.message, "error");
    },
    onSuccess: () => {
      navigate(route.dashboard.name);
    },
  });

  const login = ({ email, password }: AuthParams) => {
    mutate({ email, password });
  };

  return { login, isLoading, data, error };
}
