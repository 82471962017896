import React from "react";
import Box, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { format } from "date-fns";
import { pl } from "date-fns/locale";

const Wrapper = styled(Box)(({ theme }) => ({
  border: `solid 1px ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  borderRadius: theme.spacing(2),
  padding: theme.spacing(1),
  fontWeight: 500,
  textTransform: "uppercase",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  width: 56,
  height: 56,

  [theme.breakpoints.down("sm")]: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    gap: theme.spacing(0.5),
    width: "auto",
    height: "auto",
    padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
  },
}));

interface DateBoxProps extends BoxProps {
  date: Date;
}

const DateBox = ({ date, ...props }: DateBoxProps) => {
  return (
    <Wrapper {...props}>
      <p>{format(date, "dd")}</p>
      <p>{format(date, "MMM", { locale: pl })}</p>
    </Wrapper>
  );
};

export default DateBox;
