import styled, { css } from "styled-components";

export const Wrapper = styled.div<{ disabled?: boolean }>`
  border-radius: 8px;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.palette.background.default : theme.palette.background.paper};
  border: solid 1px ${({ theme }) => theme.palette.background.default};
  font-size: 14;
  padding: 8px;
  }

  span {
    overflow-wrap: anywhere;
  }

  & > div {
    padding-right: 16px;
    padding-left: 16px;
  }
`;

interface EditableWrapperProps {
  displayPlaceholder: boolean;
}

export const EditableWrapper = styled.div<EditableWrapperProps>`
  max-height: 350px;
  overflow-y: auto;
  min-height: 180px;
  padding-top: 8px;
  padding-bottom: 12px;

  & > div[role="textbox"] {
    min-height: inherit;
  }

  // Override default placeholder styles
  ${({ displayPlaceholder }) =>
    displayPlaceholder &&
    css`
      span[contenteditable="false"] {
        width: 100% !important;
        white-space: pre-wrap !important;
        opacity: 1 !important;
        color: ${({ theme }) => theme.placeholder.textColor};
      }
    `}

  hr {
    margin: 24px 0;
    border: solid 1px ${({ theme }) => theme.palette.background.default};
  }

  h1 {
    font-size: 20px;
    line-height: 27px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  h2 {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 8px;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  li {
    display: list-item;
  }

  a {
    cursor: pointer;
    text-decoration: underline;
  }

  [data-slate-editor] > * + * {
    margin-top: 16px;
  }
`;
