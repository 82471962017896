import React from "react";
import { Container } from "@mui/material";

import ResetPasswordForm from "./reset-password-form";

const ResetPassword = (): JSX.Element => {
  return (
    <Container>
      <ResetPasswordForm />
    </Container>
  );
};

export default ResetPassword;
