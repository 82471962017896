import React, { ReactChild } from "react";
import OContainer, { ContainerProps } from "@mui/material/Container";
import { styled } from "@mui/material/styles";

const Container = styled(OContainer)(({ theme }) => ({
  minHeight: "calc(100vh - 64px)",
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

interface Props extends ContainerProps {
  children: ReactChild;
}

const PageContentWrapper = ({ children, ...props }: Props): JSX.Element => {
  return <Container {...props}>{children}</Container>;
};

export default PageContentWrapper;
