import React from "react";
import { Helmet } from "react-helmet";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

import ContactImage from "../../images/contact.svg";
import PageContentWrapper from "../../components/page-content-wrapper";

const Contact = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"), { noSsr: true });

  return (
    <>
      <Helmet>
        ‍<title>{t("page-title.contact-us")}</title>‍
      </Helmet>
      <PageContentWrapper>
        <Grid container spacing={4} alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              {t("contact.contact-us")}
            </Typography>
            <Typography variant={matches ? "h2" : "h4"} sx={{ fontWeight: 900 }} gutterBottom>
              {t("contact.contact-us-heading")}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t("contact.contact-us-description")}
            </Typography>
            <Typography variant="h6" color="primary" sx={{ fontWeight: 600 }}>
              {t("contact.email")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <img src={ContactImage} alt={t("contact.contact-us")} width="100%" />
          </Grid>
        </Grid>
      </PageContentWrapper>
    </>
  );
};

export default Contact;
