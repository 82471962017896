import React from "react";
import { Navigate, RouteProps } from "react-router-dom";

import { useAuth } from "../../auth-provider-supabase";

const PrivateRoute = ({ ...routeProps }: RouteProps): JSX.Element => {
  const { userId } = useAuth();

  return userId ? <>{routeProps.children}</> : <Navigate to="/login" />;
};

export default PrivateRoute;
