import { Editor, Range, Transforms, Element, LinkElement } from "slate";

export const isLinkActive = (editor: Editor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === "link",
  });
  return !!link;
};

export const unwrapLink = (editor: Editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === "link",
  });
};

export const wrapLink = (editor: Editor, url: string, openInNewTab: boolean) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: LinkElement = {
    type: "link",
    url,
    openInNewTab,
    children: isCollapsed ? [{ text: url }] : [],
  };

  Transforms.wrapNodes(editor, link, { split: true });
  Transforms.collapse(editor, { edge: "end" });
};

export const getLink = (editor: Editor): LinkElement | undefined => {
  const [link] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === "link",
  });

  if (!link) return undefined;

  const node = link[0] as LinkElement;
  return Element.isElement(node) ? node : undefined;
};

export const insertLink = (editor: Editor, url: string, openInNewTab: boolean) => {
  if (editor.selection) {
    wrapLink(editor, url, openInNewTab);
  }
};
