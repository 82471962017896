import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import PageContentWrapper from "../../components/page-content-wrapper";

import LoginForm from "./login-form";

const Login = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        ‍<title>{t("page-title.login")}</title>‍
      </Helmet>
      <PageContentWrapper>
        <LoginForm />
      </PageContentWrapper>
    </>
  );
};

export default Login;
