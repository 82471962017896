import React from "react";
import Checkbox from "@mui/material/Checkbox";
import OList from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const List = styled(OList)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up("sm")]: {
    maxWidth: 360,
  },
}));

interface Props {
  onSelect: (type: string) => void;
  toggleAll: (e: React.ChangeEvent<HTMLInputElement>) => void;
  options: string[];
  selected: string[];
  title: string;
  isOpen?: boolean;
}

const SelectDropdown = ({ onSelect, toggleAll, options, selected, title, isOpen = false }: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(isOpen);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List aria-labelledby="nested-list-types" sx={{ position: "relative", boxShadow: 3, borderRadius: 2 }}>
      <ListItemButton onClick={handleClick}>
        <ListItemText primary={t(`${title}`, { count: 1 })} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit sx={{ width: "100%" }}>
        <List>
          <ListItem
            secondaryAction={
              <Checkbox
                edge="end"
                onChange={toggleAll}
                checked={options.length === selected.length}
                indeterminate={!!options.length && options.length > selected.length}
                inputProps={{ "aria-labelledby": "checkbox-list-all" }}
              />
            }
            disablePadding
          >
            <ListItemButton>
              <ListItemText id={"checkbox-list-all"} primary={t("all")} />
            </ListItemButton>
          </ListItem>
          {options.map((option) => {
            const labelId = `checkbox-list-${option}`;
            return (
              <ListItem
                key={option}
                secondaryAction={
                  <Checkbox
                    edge="end"
                    onChange={() => onSelect(option)}
                    checked={selected.includes(option)}
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                }
                disablePadding
              >
                <ListItemButton>
                  <ListItemText id={labelId} secondary={t(`${option}`, { count: 2 })} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </List>
  );
};

export default SelectDropdown;
