import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { Image } from "mui-image";

import PageViewLoader from "../../components/page-view-loader";
import EventDetailsHighlights from "../../components/event-details-highlights";
import PageContentWrapper from "../../components/page-content-wrapper";
import FALLBACK_IMAGE from "../../images/event-fallback.png";
import { useEvent } from "./use-event";

const Header = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
}));

const Description = styled("div")(() => ({
  "& p:not(:first-child)": {
    marginTop: 16,
  },
}));

const EventDetails = (): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"), { noSsr: true });
  const { eventSlug } = useParams();

  const { event, isLoading, error } = useEvent({ eventSlug });
  const { title, type, description, organizer, exhibitorInfo, visitorInfo, imageUrl } = event || {};

  if (error) return <PageContentWrapper>{"Error"}</PageContentWrapper>;

  if (isLoading) {
    return (
      <PageContentWrapper>
        <PageViewLoader />
      </PageContentWrapper>
    );
  }

  if (!event) return <PageContentWrapper>{"no event"}</PageContentWrapper>;

  return (
    <>
      <Helmet>
        <title>{title?.pl?.value} | Dogspace</title>
        <meta property="og:url" content={`https://www.dogspace.pl/wydarzenia/${event.category}/${event.eventSlug}`} />
        <meta property="og:title" content={title?.pl?.value} />
        <meta property="og:image" content={imageUrl || FALLBACK_IMAGE} />
      </Helmet>
      <PageContentWrapper>
        <>
          <Card sx={{ mb: 4 }}>
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  <Image
                    src={imageUrl || FALLBACK_IMAGE}
                    showLoading={isLoading}
                    duration={0}
                    sx={{ borderRadius: 0.5, mb: !matches ? 2 : 0 }}
                  />
                </Grid>
                <Grid item xs={12} md={8} justifyContent="space-between">
                  <Header>
                    <Box>
                      <Typography variant="h6" gutterBottom>
                        {t(`${type}`, { count: 1 })}
                      </Typography>
                      <Typography variant="h4" gutterBottom>
                        {title?.pl?.value}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body1">{`${t("event-details.organizer")}: ${organizer}`}</Typography>
                    </Box>
                  </Header>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Grid container spacing={4} direction={matches ? "row" : "column-reverse"}>
            <Grid item md={8}>
              <Box>
                {!!description?.pl?.value && (
                  <Card>
                    <CardContent>
                      <Typography>
                        <Description dangerouslySetInnerHTML={{ __html: description.pl.value }} />
                      </Typography>
                    </CardContent>
                  </Card>
                )}
                {visitorInfo && (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>{t("event-details.visitor-info")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <div dangerouslySetInnerHTML={{ __html: visitorInfo }} />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                )}
                {exhibitorInfo && (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>{t("event-details.exhibitor-info")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <div dangerouslySetInnerHTML={{ __html: exhibitorInfo }} />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                )}
              </Box>
            </Grid>
            <Grid item md={4}>
              <EventDetailsHighlights event={event} />
            </Grid>
          </Grid>
        </>
      </PageContentWrapper>
    </>
  );
};

export default EventDetails;
