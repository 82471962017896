import React, { useState, ChangeEvent } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { Box, Button, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Add from "@mui/icons-material/Add";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Image } from "mui-image";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { format, parseISO } from "date-fns";

import PageViewLoader from "../../components/page-view-loader";
import PageContentWrapper from "../../components/page-content-wrapper";
import ButtonLoading from "../../components/button-loading";
import NoEventsImage from "../../images/add-event.svg";
import PLACEHOLDER from "../../images/placeholder.png";
import { useToast } from "../../components/toast";

import { useMyEvents } from "./use-my-events";
import { useDeleteEvent } from "./use-delete-event";
import { useUpdateEventVisibility } from "./use-update-event-visibility";

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: theme.spacing(2),
}));

interface SelectEventProps {
  e: ChangeEvent<HTMLInputElement>;
  eventId: string;
}

const MyEvents = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("page-title.my-events")}</title>
      </Helmet>
      <View />
    </>
  );
};

const View = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedEvent, setSelectedEvent] = useState<string>("");
  const { showToast } = useToast();
  const { myEvents, isLoading, isError, refetch } = useMyEvents();
  const { deleteEvent, isLoading: isLoadingDelete } = useDeleteEvent({
    onSuccess: () => {
      showToast(t("my-events.event-deleted"));
      setSelectedEvent("");
      refetch();
    },
  });
  const { updateEvent } = useUpdateEventVisibility();

  const onClickEvent = (eventId: string) => {
    navigate(`/dashboard/moje-wydarzenia/edytuj/${eventId}`);
  };

  const onDeleteEvent = (eventId: string) => {
    deleteEvent({ eventId });
  };

  const onSelectEvent = ({ e, eventId }: SelectEventProps) => {
    if (e.target.checked) {
      setSelectedEvent(eventId);
    } else {
      setSelectedEvent("");
    }
  };

  if (isLoading || (!myEvents && !isError)) return <PageViewLoader />;

  if (!myEvents?.length)
    return (
      <PageContentWrapper style={{ textAlign: "center" }}>
        <>
          <Typography variant="h5" sx={{ mb: 4 }}>
            {t("my-events.no-events")}
          </Typography>
          <Button
            sx={{ mb: 4 }}
            variant="contained"
            color="primary"
            startIcon={<Add />}
            component={Link}
            to={"/dashboard"}
          >
            {t("my-events.add-event")}
          </Button>
          <Image src={NoEventsImage} sx={{ maxWidth: 450 }} height="auto" duration={0} />
        </>
      </PageContentWrapper>
    );
  return (
    <>
      <ButtonWrapper>
        {selectedEvent && (
          <ButtonLoading
            variant="outlined"
            color="primary"
            loading={isLoadingDelete}
            disabled={isLoadingDelete}
            onClick={() => onDeleteEvent(selectedEvent)}
          >
            {t("delete")}
          </ButtonLoading>
        )}
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          component={Link}
          to={"/dashboard"}
          sx={{ marginLeft: "auto" }}
        >
          {t("my-events.add-event")}
        </Button>
      </ButtonWrapper>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: 50 }}></TableCell>
              <TableCell sx={{ width: 150 }} align="center">
                {t("my-events.image")}
              </TableCell>
              <TableCell align="left" sx={{ minWidth: 150 }}>
                {t("my-events.title")}
              </TableCell>
              <TableCell align="left" sx={{ minWidth: 150 }}>
                {t("my-events.start")}
              </TableCell>
              <TableCell align="left" sx={{ minWidth: 150 }}>
                {t("my-events.end")}
              </TableCell>
              <TableCell align="left" sx={{ minWidth: 150 }}>
                {t("my-events.localization")}
              </TableCell>
              <TableCell align="left" sx={{ width: 100 }}>
                {t("my-events.visibility")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {myEvents.map((event) => (
              <TableRow
                key={event.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: "pointer" }}
                hover
                selected={selectedEvent === event.id}
                onClick={() => onClickEvent(event.id)}
              >
                <TableCell component="th" scope="row" align="center">
                  <Checkbox
                    checked={selectedEvent === event.id}
                    disabled={!!selectedEvent && selectedEvent !== event.id}
                    onChange={(e) => onSelectEvent({ e, eventId: event.id })}
                    onClick={(e) => e.stopPropagation()}
                  />
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  <Image
                    alt={event.title?.pl?.value}
                    src={event.imageUrl || PLACEHOLDER}
                    duration={0}
                    width="100%"
                    height={80}
                    sx={{ borderRadius: 0.5 }}
                  />
                </TableCell>
                <TableCell align="left">{event.title.pl.value}</TableCell>
                <TableCell align="left">
                  <div>{format(parseISO(event.startDate), "dd-MM-yyyy")}</div>
                  <div>{format(parseISO(event.startDate), "HH:mm")}</div>
                </TableCell>
                <TableCell align="left">
                  <div>{format(parseISO(event.endDate), "dd-MM-yyyy")}</div>
                  <div>{format(parseISO(event.endDate), "HH:mm")}</div>
                </TableCell>
                <TableCell align="left">
                  {event.isOnline ? t("my-events.online") : event.place?.formatted_address}
                </TableCell>
                <TableCell align="center">
                  <Tooltip title={event.isPublished ? t("my-events.published")! : t("my-events.unpublished")!}>
                    <IconButton
                      aria-label={event.isPublished ? t("my-events.published")! : t("my-events.unpublished")!}
                      onClick={(e) => {
                        e.stopPropagation();
                        updateEvent({ eventId: event.id, isPublished: !event.isPublished });
                      }}
                      size="large"
                    >
                      {event.isPublished ? <Visibility color="primary" /> : <VisibilityOff color="error" />}
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default MyEvents;
