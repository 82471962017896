export enum Organization {
  ZKWP = "Związek Kynologiczny w Polsce",
  FCI = "Fédération Cynologique Internationale",
}

export type EventType =
  | "world-exhibition"
  | "international-exhibition"
  | "national-exhibition"
  | "club-exhibition"
  | "specialized-exhibition"
  | "workshop"
  | "webinar"
  | "course"
  | "conference"
  | "competition"
  | "other"
  | "training"
  | "training-competition"
  | "seminar"
  | "camp"
  | "IGP"
  | "BH-VT"
  | "PT"
  | "PTT";

export type EventCategory = "exhibition" | "exam" | "agility" | "obedience" | "dogfrisbee" | "puller" | "training";
export type AgilityClass = "A0" | "A1" | "A2" | "A3";
export type AgilitySize = "XS" | "S" | "M" | "ML" | "L";

interface ObedienceClass {
  [key: string]: boolean;
}

export interface Translation {
  [code: string]: {
    value: string;
  };
}
export interface Event {
  id: string;
  userId: string;
  title: Translation;
  eventSlug: string;
  type: EventType;
  startDate: string;
  endDate: string;
  street: string;
  city: string;
  country: string;
  imageUrl: string;
  organizer: string;
  www: string;
  facebook: string;
  email: string;
  registrationLink?: string;
  phone: string;
  exhibitorInfo?: string;
  visitorInfo?: string;
  description?: Translation[];
  isOnline: boolean;
  obedienceClass?: ObedienceClass;
  agilityClass?: AgilityClass[];
  agilitySize?: AgilitySize[];
  place: google.maps.GeocoderResult | null;
}

export interface Language {
  [code: string]: {
    icon: string;
    code: string;
  };
}

export enum PolandVoivodeship {
  DS = "Dolnośląskie",
  KP = "Kujawsko-Pomorskie",
  LU = "Lubelskie",
  LB = "Lubuskie",
  LD = "Łódzkie",
  MA = "Małopolskie",
  MZ = "Mazowieckie",
  OP = "Opolskie",
  PK = "Podkarpackie",
  PD = "Podlaskie",
  PM = "Pomorskie",
  SL = "Śląskie",
  SK = "Świętokrzyskie",
  WM = "Warmińsko-Mazurskie",
  WP = "Wielkopolskie",
  ZP = "Zachodniopomorskie",
}
