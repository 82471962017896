import React from "react";
import { CustomElement } from "slate";

import { BlockFormat } from "../types";

export interface ElementProps {
  children: any;
  element: CustomElement;
  attributes: {
    "data-slate-node": "element";
    "data-slate-inline"?: true;
    "data-slate-void"?: true;
    dir?: "rtl";
    ref: any;
  };
}

export function Element({ attributes, children, element }: ElementProps) {
  switch (element.type) {
    case BlockFormat.H1:
      return <h1 {...attributes}>{children}</h1>;
    case BlockFormat.H2:
      return <h2 {...attributes}>{children}</h2>;
    case BlockFormat.LI:
      return <li {...attributes}>{children}</li>;
    case BlockFormat.UL:
      return <ul {...attributes}>{children}</ul>;
    case BlockFormat.OL:
      return <ol {...attributes}>{children}</ol>;
    case "hr":
      return (
        <p {...attributes}>
          <hr />
          {children}
        </p>
      );
    case "link":
      return element.openInNewTab ? (
        <a {...attributes} href={element.url} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ) : (
        <a {...attributes} href={element.url}>
          {children}
        </a>
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
}
