import React, { useMemo, useCallback } from "react";
import { createEditor, Node, CustomEditor } from "slate";
import { Slate, Editable, withReact } from "slate-react";
import { withHistory } from "slate-history";

import { withLinks } from "./plugins/withLinks";
import { withHtml } from "./plugins/withHtml";
import { Element, Leaf } from "./components";
import Toolbar from "./toolbar";
import { Wrapper, EditableWrapper } from "./style";
import useWysiwygEditorSlate, { UseWysiwygEditorParams, UseWysiwygEditorResult } from "./use-wysiwyg-editor-slate";

export interface WysiwygEditorSlateProps {
  editorValue: Node[];
  onChange: (newValue: Node[]) => void;
  onBlur: () => void;
  onFocus: () => void;
  loading: boolean;
  success: boolean;
  disabled?: boolean;
  placeholder?: string;
}

function WysiwygEditorSlate({
  editorValue,
  onChange,
  onBlur,
  onFocus,
  disabled = false,
  placeholder,
}: WysiwygEditorSlateProps): JSX.Element {
  const editor = useMemo(() => withLinks(withHtml(withHistory(withReact(createEditor() as CustomEditor)))), []);
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  return (
    <Wrapper disabled={disabled}>
      <Slate editor={editor} value={editorValue} onChange={onChange}>
        <Toolbar disabled={disabled} />
        <EditableWrapper displayPlaceholder={!!placeholder && !editorValue}>
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            onBlur={onBlur}
            onFocus={onFocus}
            readOnly={disabled}
            placeholder={placeholder}
          />
        </EditableWrapper>
      </Slate>
    </Wrapper>
  );
}

export default WysiwygEditorSlate;
export { useWysiwygEditorSlate };
export type { UseWysiwygEditorParams, UseWysiwygEditorResult };
