import React, { useState, useEffect, ReactNode } from "react";
// import { Session, User as SupabaseUser } from "@supabase/gotrue-js/src/lib/types";
import supabase from "../supabase";

import { definitions } from "../types/supabase";

export { useSignup } from "./use-signup";
export { useLogin } from "./use-login";
export { useLogout } from "./use-logout";
export { useResetPassword } from "./use-reset-password";

interface UserContext {
  currentUser: definitions["users"] | null;
  loadingUser: boolean;
  userId: string | null;
}

export const AuthContext = React.createContext<UserContext | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [userId, setUserId] = useState<string | null>(null);
  const [currentUser, setCurrentUser] = useState<definitions["users"] | null>(null);
  const [loading, setLoading] = useState(true);
  const [loadingUser, setLoadingUser] = useState(false);

  const getUser = async () => {
    try {
      setLoadingUser(true);
      const { data, error } = await supabase.from("users").select().eq("id", userId).single();

      if (error) {
        throw error;
      }
      setCurrentUser(data);
    } catch (error) {
      console.log({ error });
    } finally {
      setLoadingUser(false);
    }
  };

  useEffect(() => {
    // Check active sessions and sets the user
    const session = supabase.auth.session();

    setUserId(session?.user?.id ?? null);
    setLoading(false);

    // Listen for changes on auth state (logged in, signed out, etc.)
    const { data: listener } = supabase.auth.onAuthStateChange(async (event, session) => {
      setUserId(session?.user?.id ?? null);
      setLoading(false);
    });

    return () => {
      listener?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (userId) getUser();
    setCurrentUser(null);
  }, [userId]);

  return (
    <AuthContext.Provider value={{ currentUser, loadingUser, userId }}>{!loading && children}</AuthContext.Provider>
  );
};

export function useAuth(): UserContext {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}
