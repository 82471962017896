import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { route } from "../../routes";
import { useToast } from "../../components/toast";
import supabase from "../../supabase";
import { definitions } from "../../types/supabase";
import { makeId, makeSlug } from "../../utils";

interface AddEventParams {
  event: definitions["events"];
  image: File | null;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const addEventSupabase = async ({ event: { id, ...eventData }, image }: AddEventParams) => {
  let eventSlug = makeSlug(eventData.title.pl.value);
  const { data: existingSlug } = await supabase.from("events").select().eq("eventSlug", eventSlug).single();

  if (existingSlug) {
    const id = makeId(4);
    eventSlug = `${eventSlug}-${id}`;
  }

  const voivodeship = eventData.place?.address_components.find((component: google.maps.GeocoderAddressComponent) =>
    component.types.includes("administrative_area_level_1"),
  )?.long_name;

  const { data: eventAddData, error: eventAddError } = await supabase
    .from<definitions["events"]>("events")
    .insert({ ...eventData, eventSlug, voivodeship })
    .single();

  if (eventAddError) {
    throw new Error(eventAddError.message);
  }

  if (image && eventAddData) {
    const { data: uploadData, error: uploadError } = await supabase.storage
      .from("events")
      .upload(`images/${eventAddData.id}/${image.name}`, image);

    if (uploadError) {
      throw new Error(uploadError.message);
    }

    if (uploadData) {
      const { error: eventUpdateError } = await supabase
        .from<definitions["events"]>("events")
        .update({ imageUrl: `${process.env.REACT_APP_STORAGE_PUBLIC_BASE_URL}/${uploadData.Key}` })
        .eq("id", eventAddData.id)
        .single();

      if (eventUpdateError) {
        throw new Error(eventUpdateError.message);
      }
    }
  }
};

export function useAddEvent() {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { t } = useTranslation();

  const { mutate, isLoading, data, error } = useMutation(addEventSupabase, {
    onSuccess: () => {
      showToast(t("success.event-added"));
      navigate(route.dashboard.myEvents.name);
    },
    onError: (error: Error) => {
      showToast(t("error.something-went-wrong"), "error");
      showToast(error.message, "error");
    },
  });

  const addEvent = ({ event, image }: AddEventParams) => {
    mutate({ event, image });
  };

  return { addEvent, isLoading, data, error };
}
