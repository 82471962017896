import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import NotFoundPage from "../../images/page-not-found.svg";
import PageContentWrapper from "../../components/page-content-wrapper";

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>404 | Dogspace</title>
      </Helmet>
      <PageContentWrapper>
        <>
          <Typography align="center" color="textPrimary" variant="h2" gutterBottom>
            {t("page-not-found.heading")}
          </Typography>
          <Box sx={{ textAlign: "center" }}>
            <Button onClick={() => navigate("/")}>{t("page-not-found.description")}</Button>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <img
              alt="Under development"
              src={NotFoundPage}
              style={{
                marginTop: 50,
                display: "inline-block",
                maxWidth: "100%",
                width: 560,
              }}
            />
          </Box>
        </>
      </PageContentWrapper>
    </>
  );
};

export default NotFound;
