import { useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";

import { useToast } from "../toast";
import supabase from "../../supabase";
import { definitions } from "../../types/supabase";

interface UpdateOrganizationParams {
  ownerId: string;
  name: string;
  email: string;
  www: string;
  phone: string;
  address: google.maps.GeocoderResult | null;
  voivodeship?: string;
}

const updateOrganizationProfileSupabase = async ({ ownerId, ...organizationData }: UpdateOrganizationParams) => {
  const { data: eventUpdateData, error: eventUpdateError } = await supabase
    .from<definitions["organizations"]>("organizations")
    .update({ ...organizationData, updatedAt: new Date().toISOString() })
    .eq("ownerId", ownerId)
    .single();

  if (eventUpdateError) {
    throw new Error(eventUpdateError.message);
  }

  return eventUpdateData;
};

export function useUpdateOrganizationProfile() {
  const { showToast } = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate, isLoading, data, error } = useMutation(updateOrganizationProfileSupabase, {
    onSuccess: (updatedOrganization) => {
      if (updatedOrganization) {
        queryClient.setQueryData(["myOrganization", updatedOrganization.id], updatedOrganization);
      }
      showToast(t("success.organization-profile-updated"));
    },
    onError: (error: Error) => {
      showToast(t("error.something-went-wrong"), "error");
      showToast(error.message, "error");
    },
  });

  const updateOrganizationProfile = (organizationData: UpdateOrganizationParams) => {
    mutate(organizationData);
  };

  return { updateOrganizationProfile, isLoading, data, error };
}
