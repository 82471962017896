import React, { useState } from "react";
import { Link as OLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";

import { Typography, Box, Grid, TextField } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { useResetPassword } from "../../auth-provider-supabase";
import ButtonLoading from "../../components/button-loading";

const Wrapper = styled(Box)({
  textAlign: "center",
  maxWidth: 500,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "0px auto",
  minHeight: "calc(100vh - 81px)",
});

const GridItem = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const LinkWrapper = styled(Typography)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const Link = styled(OLink)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginLeft: theme.spacing(1),
}));

const ResetPasswordForm = (): JSX.Element => {
  const { t } = useTranslation();
  const { resetPassword, isLoading } = useResetPassword();
  const [email, setEmail] = useState("");

  const handleChange = (event: any) => {
    event.persist();
    setEmail(event.target.value);
  };

  const handleSubmit = async (event: any) => {
    event?.preventDefault();
    resetPassword(email);
  };

  const loginDisabled = isLoading || !email;

  return (
    <Wrapper>
      <Box>
        <Box sx={{ p: 1 }}>
          <Typography variant="h3">{t("reset-password-page.reset-password")}</Typography>
        </Box>

        <form onSubmit={handleSubmit}>
          <Grid container>
            <GridItem item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                type="text"
                name="email"
                placeholder={t("email")}
                onChange={handleChange}
              />
            </GridItem>
            <GridItem item xs={12}>
              <ButtonLoading
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                type="submit"
                disabled={loginDisabled}
                loading={isLoading}
              >
                {t("send")}
              </ButtonLoading>
            </GridItem>
          </Grid>
          <GridItem item xs={12}>
            <LinkWrapper variant="subtitle1" color="textSecondary">
              {t("reset-password-page.signup-redirect")}
              <Link to="/rejestracja">
                <span>{t("signup")}</span>
                <ArrowForwardIcon />
              </Link>
            </LinkWrapper>
          </GridItem>
        </form>
      </Box>
    </Wrapper>
  );
};
export default ResetPasswordForm;
