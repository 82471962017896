import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Card, CardContent, CardHeader, Divider, Grid, Autocomplete, TextField } from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";
import isURL from "validator/lib/isURL";
import isNumeric from "validator/lib/isNumeric";
import isAlphanumeric from "validator/lib/isAlphanumeric";
import throttle from "lodash/throttle";
import { Controller } from "react-hook-form";

import FormTextField from "../form-text-field";
import { definitions } from "../../types/supabase";
import ButtonLoading from "../button-loading";
import { EMAIL_REGEX } from "../../constants";

import { useUpdateOrganizationProfile } from "./use-update-organization-profile";

interface Props {
  organization: definitions["organizations"];
}
interface FormItems {
  name: string;
  email: string;
  www: string;
  phone: string;
  address: google.maps.GeocoderResult | null;
}

const OrganizationProfileDetails = ({ organization }: Props) => {
  const { t } = useTranslation();
  const { ownerId, email, name, www, phone, address } = organization;
  const geocoder = new google.maps.Geocoder();
  const [localizations, setLocalizations] = useState<google.maps.GeocoderResult[]>([]);

  const { updateOrganizationProfile, isLoading } = useUpdateOrganizationProfile();

  const {
    handleSubmit,
    formState: { isValid },
    control,
  } = useForm<FormItems>({
    mode: "onChange",
    defaultValues: {
      name: name || "",
      email: email || "",
      www: www || "",
      phone: phone || "",
      address: address || null,
    },
  });

  const onSubmit: SubmitHandler<FormItems> = ({ ...data }) => {
    const voivodeship = data.address?.address_components.find((component: google.maps.GeocoderAddressComponent) =>
      component.types.includes("administrative_area_level_1"),
    )?.long_name;

    updateOrganizationProfile({ ownerId, voivodeship, ...(data as FormItems) });
  };

  const getLocalizations = throttle((address: string) => {
    if (address) {
      geocoder.geocode({ address, region: "EU" }, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK && results) {
          setLocalizations(results);
        } else {
          console.log("Geocode was not successful for the following reason: " + status);
        }
      });
    }
  }, 1000);

  return (
    <>
      <Card sx={{ mb: 4 }}>
        <CardHeader title={t("my-organization.my-organization")} />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormTextField
                name="name"
                control={control}
                label={t("my-organization.name")}
                placeholder={t("my-organization.name")}
                rules={{
                  validate: (value: string) => {
                    return (
                      isAlphanumeric(value, "pl-PL", { ignore: " -" }) ||
                      !value ||
                      t("my-organization.error-organizer-pattern")
                    );
                  },
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <CardHeader title={t("my-organization.organizer-information")} />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormTextField
                fullWidth
                label={t("email")}
                name="email"
                control={control}
                rules={{
                  pattern: {
                    value: EMAIL_REGEX,
                    message: t("my-organization.error-email-pattern"),
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name="phone"
                control={control}
                label={t("my-organization.phone")}
                placeholder={t("my-organization.phone")}
                rules={{
                  validate: (value: string) =>
                    isNumeric(value, { no_symbols: true }) || !value || t("my-organization.error-phone-pattern"),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name="www"
                control={control}
                label={t("my-organization.www")}
                placeholder={t("my-organization.www")}
                rules={{
                  require: false,
                  validate: (value: string) =>
                    isURL(value, {
                      protocols: ["http", "https"],
                      require_protocol: true,
                    }) ||
                    !value ||
                    t("my-organization.error-www-pattern"),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="address"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    options={localizations}
                    filterOptions={(x) => x}
                    onChange={(e: any, value: google.maps.GeocoderResult | null) => {
                      console.log({ value });
                      onChange(value);
                    }}
                    onInputChange={(event, newInputValue) => {
                      getLocalizations(newInputValue);
                    }}
                    defaultValue={address}
                    value={value as google.maps.GeocoderResult | null}
                    fullWidth
                    autoHighlight
                    getOptionLabel={(option: google.maps.GeocoderResult) => option.formatted_address}
                    isOptionEqualToValue={(option, value) => option.formatted_address === value?.formatted_address}
                    renderOption={(props, option: google.maps.GeocoderResult) => (
                      <li {...props}>{option.formatted_address}</li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("event-form.address")}
                        placeholder={t("event-form.address")}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
          }}
        >
          <ButtonLoading
            size="large"
            variant="contained"
            color="primary"
            type="submit"
            disabled={!isValid || isLoading}
            loading={isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {t("save")}
          </ButtonLoading>
        </Box>
      </Card>
    </>
  );
};

export default OrganizationProfileDetails;
