import React from "react";
import { Helmet } from "react-helmet";
import { Container, Grid } from "@mui/material";
import Add from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { Image } from "mui-image";
import { useTranslation } from "react-i18next";

import PageViewLoader from "../../components/page-view-loader";
import PageContentWrapper from "../../components/page-content-wrapper";
import NoEventsImage from "../../images/add-event.svg";
import ButtonLoading from "../../components/button-loading";

import OrganizationProfile from "../../components/organization-profile";
import OrganizationProfileDetails from "../../components/organization-profile-details";

import { useMyOrganization } from "./use-my-organization";
import { useCreateOrganization } from "./use-create-organization";

const MyOrganization = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("page-title.my-organization")}</title>
      </Helmet>
      <View />
    </>
  );
};

const View = (): JSX.Element => {
  const { t } = useTranslation();
  const { organization, isLoading } = useMyOrganization();

  const { createOrganization, isLoading: isLoadingCreate } = useCreateOrganization();

  if (isLoading) return <PageViewLoader />;

  if (!organization)
    return (
      <PageContentWrapper style={{ textAlign: "center" }}>
        <>
          <Typography variant="h5" sx={{ mb: 4 }}>
            {t("my-organization.no-organizaition")}
          </Typography>
          <ButtonLoading
            sx={{ mb: 4 }}
            variant="contained"
            color="primary"
            startIcon={<Add />}
            loading={isLoadingCreate}
            onClick={() => createOrganization()}
          >
            {t("my-organization.add-organization")}
          </ButtonLoading>
          <Image src={NoEventsImage} sx={{ maxWidth: 450 }} height="auto" duration={0} />
        </>
      </PageContentWrapper>
    );

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item lg={4} md={6} xs={12}>
          <OrganizationProfile organization={organization} />
        </Grid>
        <Grid item lg={8} md={6} xs={12}>
          <OrganizationProfileDetails organization={organization} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default MyOrganization;
