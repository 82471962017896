import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";

import supabase from "../../supabase";

import Topbar from "../topbar";
import Sidebar from "../sidebar";
import Copyright from "../copyright";
import Footer from "../footer";

const MainLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const MainLayoutWrapper = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: 64,
});

const MainLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const MainLayoutContent = styled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
});

const PageContent = styled("div")({
  flex: "1 1 auto",
  height: "auto",
});

const Layout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { pathname } = useLocation();
  const { eventSlug } = useParams();

  const incrementPageView = async () => {
    await supabase.rpc("increment_page_view", { page_slug: eventSlug });
  };

  useEffect(() => {
    if (eventSlug && pathname.startsWith("/wydarzenia/")) {
      incrementPageView();
    }
  }, [eventSlug]);

  return (
    <MainLayoutRoot>
      <Topbar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <Sidebar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />
      <MainLayoutWrapper>
        <MainLayoutContainer>
          <MainLayoutContent id="layout">
            <PageContent>
              <Outlet />
            </PageContent>
            <Footer />
            <Copyright />
          </MainLayoutContent>
        </MainLayoutContainer>
      </MainLayoutWrapper>
    </MainLayoutRoot>
  );
};

export default Layout;
