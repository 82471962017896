import styled, { keyframes } from "styled-components";
import MuiAlert from "@mui/material/Alert";

export const Wrapper = styled.div`
  position: fixed;
  bottom: 16px;
  display: flex;
  justify-content: center;
  width: 95%;
  margin: 0 auto;
  z-index: 9999999;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    top: 30px;
  }
  to {
    opacity: 1;
    top: 0px;
  }
`;

export const Alert = styled(MuiAlert)`
  animation: ${() => fadeIn} ease-in-out 300ms;
`;
