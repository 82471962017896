import React from "react";
import { Helmet } from "react-helmet";
import { Box, Container, Grid } from "@mui/material";

import Profile from "../../components/profile";
import ProfileDetails from "../../components/profile-details";
import PageViewLoader from "../../components/page-view-loader";
import { useAuth } from "../../auth-provider-supabase";
import { useTranslation } from "react-i18next";

const ProfilePage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("page-title.profile")}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <View />
      </Box>
    </>
  );
};

const View = () => {
  const { currentUser, loadingUser } = useAuth();

  if (loadingUser) return <PageViewLoader />;
  if (!currentUser) return null;

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item lg={4} md={6} xs={12}>
          <Profile currentUser={currentUser} />
        </Grid>
        <Grid item lg={8} md={6} xs={12}>
          <ProfileDetails currentUser={currentUser} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProfilePage;
