import { useQuery } from "react-query";

import supabase from "../../supabase";
import { definitions } from "../../types/supabase";

const getEventSupabase = async ({ id }: { id: string }) => {
  const { data, error } = await supabase.rpc<definitions["events"]>("get_my_event", { event_id: id }).single();
  // const { data, error } = await supabase.from<definitions["events"]>("events").select().eq("id", id).single();
  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export function useEvent({ id = "" }: { id?: string }) {
  const { isLoading, data, error } = useQuery<definitions["events"] | null, Error>(
    ["myEventEdit", id],
    () => getEventSupabase({ id }),
    {
      enabled: !!id,
    },
  );

  return { event: data, isLoading, error };
}
