import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box, Button, Divider, Drawer, Hidden, List, Typography } from "@mui/material";
import { Person as UserIcon, Event, CorporateFare } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import { useAuth, useLogout } from "../../auth-provider-supabase";
import NavItem from "../navitem";

const items = [
  {
    href: "/dashboard/moja-organizacja",
    icon: CorporateFare,
    title: "dashboard.my-organization",
  },
  {
    href: "/dashboard/moje-wydarzenia",
    icon: Event,
    title: "dashboard.my-events",
  },
  {
    href: "/dashboard/profil",
    icon: UserIcon,
    title: "dashboard.profile",
  },
];

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const DashboardSidebar = ({ onMobileClose, openMobile }: DashboardSidebarProps): JSX.Element => {
  const location = useLocation();
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const { logout } = useLogout();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          padding: 16,
        }}
      >
        {/* <Avatar
          component={RouterLink}
          src={user.avatar}
          sx={{
            cursor: "pointer",
            width: 64,
            height: 64,
            mb: 2,
          }}
          to="/app/account"
        /> */}
        <Typography color="textPrimary" variant="h5">
          {currentUser?.username}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {currentUser?.email}
        </Typography>
      </Box>
      <Divider />
      <Box style={{ padding: 16 }}>
        <List>
          {items.map((item) => (
            <NavItem href={item.href} key={item.title} title={item.title} icon={item.icon} />
          ))}
        </List>
      </Box>
      <Box style={{ flexGrow: 1 }} />
      <Box
        style={{
          backgroundColor: "background.default",
          margin: 16,
          padding: 16,
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button color="primary" variant="contained" onClick={() => logout()}>
            {t("logout")}
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden mdUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            style: {
              width: 256,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            style: {
              width: 256,
              top: 64,
              height: "calc(100% - 64px)",
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default DashboardSidebar;
