import React from "react";
import { Box, Typography as OTypography, Container, Grid } from "@mui/material";
import { Link as OLink } from "react-router-dom";
import OList from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import BackgroundImage from "../../images/bg/footer_bg.svg";
import { EVENT_TYPES } from "../../constants";
import logo from "../../images/logo-text.svg";

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary["400"],
  position: "relative",
  padding: `${theme.spacing(10)} ${theme.spacing(0)}`,

  "&::before": {
    content: "''",
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    backgroundImage: `url(${BackgroundImage})`,
    opacity: "0.04",
  },
}));

const Link = styled(OLink)(({ theme }) => ({
  color: "inherit",

  "&:visited": {
    color: "inherit",
  },
  "&:hover": {
    color: theme.palette.secondary["800"],
  },
}));

const List = styled(OList)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
}));

const Typography = styled(OTypography)(({ theme }) => ({
  fontWeight: 800,
  fontSize: 20,
  marginBottom: theme.spacing(2),
}));

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Container sx={{ position: "relative", zIndex: 1 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h4">{t("agility")}</Typography>
            <List>
              {EVENT_TYPES.agility.map((eventType) => (
                <ListItem key={eventType} disablePadding>
                  <Link to={`/wydarzenia/agility?type=${eventType}`}>
                    <ListItemText primary={t(`${eventType}`, { count: 2 })} />
                  </Link>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h4">{t("obedience")}</Typography>
            <List>
              {EVENT_TYPES.obedience.map((eventType) => (
                <ListItem key={eventType} disablePadding>
                  <Link to={`/wydarzenia/obedience?type=${eventType}`}>
                    <ListItemText primary={t(`${eventType}`, { count: 2 })} />
                  </Link>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h4">{t("dogspace")}</Typography>
            <List>
              <ListItem disablePadding>
                <Link to="/polityka-prywatnosci">
                  <ListItemText primary={t("footer.privacy-policy")} />
                </Link>
              </ListItem>
              <ListItem disablePadding>
                <Link to="/kontakt">
                  <ListItemText primary={t("footer.contact")} />
                </Link>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box>
              <img src={logo} width="50%" />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default Footer;
