import { Node } from "slate";

export const EmptyDocument: Node[] = [
  {
    type: "paragraph",
    children: [
      {
        text: "",
      },
    ],
  },
];
