import React from "react";
import { Avatar, Box, Card, CardContent, Typography } from "@mui/material";
import { definitions } from "../../types/supabase";

interface Props {
  currentUser: definitions["users"];
}

const Profile = ({ currentUser }: Props) => {
  const { avatarUrl, firstName, lastName, username } = currentUser || {};

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Avatar
            src={avatarUrl || "/static/images/avatars/avatar_6.png"}
            sx={{
              height: 100,
              width: 100,
              mb: 2,
            }}
          />
          {(firstName || lastName) && (
            <Typography color="textPrimary" gutterBottom variant="h5">
              {`${firstName || ""} ${lastName || ""}`}
            </Typography>
          )}
          <Typography color="textSecondary" variant="body1">
            {username}
          </Typography>
        </Box>
      </CardContent>
      {/* <Divider />
      <CardActions>
        <Button color="primary" fullWidth variant="text">
          {t("profile.upload-picture")}
        </Button>
      </CardActions> */}
    </Card>
  );
};

export default Profile;
