import React from "react";
import { Link as OLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { AppBar as OAppBar, AppBarProps, Box, Hidden, IconButton, Toolbar, Typography, Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/material/styles";

import { route } from "../../routes";
import logo from "../../images/logo-topbar.svg";
import { useAuth } from "../../auth-provider-supabase";

import NavLink from "../nav-link";

const AppBar = styled(OAppBar)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.shadows[0],
  borderBottom: `solid 1px ${theme.palette.divider}`,
  height: 64,
}));

const Link = styled(OLink)(({ theme }) => ({
  marginLeft: theme.spacing(3),
}));

export const LINKS = [
  {
    name: "agility",
    href: "/wydarzenia/agility",
  },
  {
    name: "obedience",
    href: "/wydarzenia/obedience",
  },
  {
    name: "dogfrisbee",
    href: "/wydarzenia/dogfrisbee",
  },
  {
    name: "puller",
    href: "/wydarzenia/puller",
  },
];

interface DashboardNavBarProps extends AppBarProps {
  onMobileNavOpen: () => void;
}

const Topbar = ({ onMobileNavOpen, ...rest }: DashboardNavBarProps): JSX.Element => {
  const { t } = useTranslation();
  const { currentUser } = useAuth();

  return (
    <AppBar elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Box>
            <img src={logo} height={40} />
          </Box>
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <Hidden mdDown>
          {LINKS.map(({ name, href }) => (
            <NavLink to={href} key={name} sx={{ ml: 3 }}>
              <Typography variant="h6" color="inherit" sx={{ fontWeight: 400 }}>
                {t(`${name}`, { count: 2 })}
              </Typography>
            </NavLink>
          ))}
          {currentUser ? (
            <Link to={route.dashboard.name}>
              <Button color="primary" variant="contained">
                {t("my-account")}
              </Button>
            </Link>
          ) : (
            <>
              <Link to="/login">
                <Button variant="outlined">{t("login")}</Button>
              </Link>
              <Link to={route.signup.name}>
                <Button color="primary" variant="contained">
                  {t("register")}
                </Button>
              </Link>
            </>
          )}
        </Hidden>
        <Hidden mdUp>
          <IconButton onClick={onMobileNavOpen} size="large">
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
