import { useCallback, useReducer } from "react";
import { Node } from "slate";

import { delay } from "../../utils";

import { deserializeFromHtml } from "./deserialize";
import { serializeToHtml } from "./serialize";
import { EmptyDocument } from "./utils/empty-document";

export interface State {
  dirty: boolean;
  focused: boolean;
  loading: boolean;
  success: boolean;
  valid: boolean;
  value: string;
  editorValue: Node[];
}

export const defaultInitialState: State = {
  dirty: false,
  focused: false,
  loading: false,
  success: false,
  valid: true,
  value: "",
  editorValue: EmptyDocument,
};

type OnChange = (newValue: Node[]) => void | Promise<void>;

export interface UseWysiwygEditorResult extends State {
  onBlur: () => void;
  onChange: OnChange;
  onFocus: () => void;
  setState: (state: Partial<State>) => void;
}

enum ActionType {
  CHANGE = "CHANGE",
  FOCUS = "FOCUS",
  BLUR = "BLUR",
  START_SUBMIT = "START_SUBMIT",
  END_SUBMIT = "END_SUBMIT",
  RESET_SUCCESS = "RESET_SUCCESS",
  SET_STATE = "SET_STATE",
}

export type Actions =
  | {
      type: ActionType.CHANGE;
      payload: {
        valid: boolean;
        value: string;
        editorValue: Node[];
      };
    }
  | { type: ActionType.FOCUS }
  | { type: ActionType.BLUR }
  | { type: ActionType.START_SUBMIT }
  | { type: ActionType.END_SUBMIT }
  | { type: ActionType.RESET_SUCCESS }
  | { type: ActionType.SET_STATE; state: Partial<State> };

export const reducer: React.Reducer<State, Actions> = (state = defaultInitialState, action) => {
  switch (action.type) {
    case ActionType.CHANGE: {
      return {
        ...state,
        dirty: true,
        valid: action.payload.valid,
        value: action.payload.value,
        editorValue: action.payload.editorValue,
      };
    }
    case ActionType.FOCUS: {
      return {
        ...state,
        focused: true,
      };
    }
    case ActionType.BLUR: {
      return {
        ...state,
        focused: false,
      };
    }
    case ActionType.START_SUBMIT: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.END_SUBMIT: {
      return {
        ...state,
        dirty: false,
        loading: false,
        success: true,
      };
    }
    case ActionType.RESET_SUCCESS: {
      return {
        ...state,
        success: false,
      };
    }
    case ActionType.SET_STATE: {
      return {
        ...state,
        ...action.state,
      };
    }
    default: {
      return state;
    }
  }
};

export type SaveOnBlur = (args: { value: string }) => void | Promise<void>;
export type IsValid = (value: string) => boolean;

export interface UseWysiwygEditorParams {
  initialValue?: string;
  saveOnBlur?: SaveOnBlur;
  isValid?: IsValid;
  resetDuration?: number;
}

export default function useWysiwygEditorSlate({
  initialValue = "",
  saveOnBlur,
  isValid,
  resetDuration = 3000,
}: UseWysiwygEditorParams) {
  const initialEditorValue = deserializeFromHtml(initialValue);
  const [{ dirty, focused, loading, success, valid, value, editorValue }, dispatch] = useReducer(reducer, {
    ...defaultInitialState,
    value: serializeToHtml(initialEditorValue),
    editorValue: initialEditorValue,
  });

  const onChange = useCallback((newValue: Node[]) => {
    const html = serializeToHtml(newValue);
    dispatch({
      type: ActionType.CHANGE,
      payload: {
        valid: isValid ? isValid(value) : true,
        value: html,
        editorValue: newValue,
      },
    });
  }, []);

  const onFocus = useCallback(() => {
    dispatch({ type: ActionType.FOCUS });
  }, [dispatch]);

  const onBlur = useCallback(async () => {
    dispatch({ type: ActionType.BLUR });

    if (saveOnBlur && dirty && valid) {
      dispatch({ type: ActionType.START_SUBMIT });

      await saveOnBlur({ value });

      dispatch({ type: ActionType.END_SUBMIT });

      await delay(resetDuration);

      dispatch({ type: ActionType.RESET_SUCCESS });
    }
  }, [dispatch, saveOnBlur, dirty, valid, value]);

  const setState = useCallback(
    (state: Partial<State>) => {
      dispatch({ type: ActionType.SET_STATE, state });
    },
    [dispatch],
  );

  return {
    dirty,
    focused,
    loading,
    success,
    valid,
    value,
    editorValue,
    onChange,
    onBlur,
    onFocus,
    setState,
  };
}
