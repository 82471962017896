import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import PageContentWrapper from "../../components/page-content-wrapper";

import SignUpForm from "./signup-form";

const Signup = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        ‍<title>{t("page-title.signup")}</title>‍
      </Helmet>
      <PageContentWrapper>
        <SignUpForm />
      </PageContentWrapper>
    </>
  );
};

export default Signup;
