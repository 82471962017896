import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import supabase from "../supabase";

import { route } from "../routes";

const resetPasswordSupabase = async (email: string) => {
  const { data, error } = await supabase.auth.api.resetPasswordForEmail(email);

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export function useResetPassword() {
  const navigate = useNavigate();

  const { mutate, isLoading, data, error } = useMutation((email: string) => resetPasswordSupabase(email), {
    onSuccess: () => {
      navigate(route.dashboard.name);
    },
  });

  const resetPassword = (email: string) => {
    mutate(email);
  };

  return { resetPassword, isLoading, data, error };
}
