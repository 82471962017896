import React, { useState, ChangeEvent } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
// import isAlphanumeric from "validator/lib/isAlphanumeric";
// import isEmail from "validator/lib/isEmail";
// import isNumeric from "validator/lib/isNumeric";
import isURL from "validator/lib/isURL";
import { isValid as isValidDate } from "date-fns";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
import BaseCard from "@mui/material/Card";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Help from "@mui/icons-material/Help";
import DateTimePicker from "@mui/lab/DateTimePicker";
import Tooltip from "@mui/material/Tooltip";
import throttle from "lodash/throttle";

import { useAuth } from "../../auth-provider-supabase";
import { EventType } from "../../types";
import { EVENT_TYPES } from "../../constants";
import WysiwygEditorSlate, { useWysiwygEditorSlate } from "../../components/wysiwyg-editor-slate";
import FormTextField from "../form-text-field";
import FormSelect from "../form-select";
import { definitions } from "../../types/supabase";
import FALLBACK_IMAGE from "../../images/placeholder.png";
import { useToast } from "../toast";

import SplitButton from "./save-button";
import { useOrganization } from "./use-organization";

const Card = styled(BaseCard)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const Input = styled("input")({
  display: "none",
});

const ButtonsWrapper = styled("div")(({ theme }) => ({
  background: theme.palette.background.paper,
  borderTop: `solid 1px ${theme.palette.divider}`,
  display: "flex",
  justifyContent: "flex-end",
  padding: theme.spacing(2),
  position: "fixed",
  bottom: 0,
  left: 0,
  width: "100%",
  zIndex: 1,
}));

const Image = styled("img")(({ theme }) => ({
  height: 150,
  width: 250,
  borderRadius: theme.spacing(0.5),
  marginRight: theme.spacing(2),
  objectFit: "cover",
}));

const ImageUploader = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
}));

const emptyEvent: definitions["events"] = {
  id: "",
  userId: "",
  title: { pl: { value: "" } },
  eventSlug: "",
  category: "obedience",
  type: "seminar",
  startDate: new Date().toDateString(),
  endDate: new Date().toDateString(),
  street: "",
  city: "",
  country: "PL",
  imageUrl: "",
  organizer: "",
  www: "",
  facebook: "",
  email: "",
  registrationLink: "",
  phone: "",
  exhibitorInfo: "",
  visitorInfo: "",
  description: { pl: { value: "" } },
  isOnline: false,
  isPublished: false,
  obedienceClass: null,
  place: null,
};

interface CategoryParams {
  category: definitions["events"]["category"];
}

interface OnSaveParams {
  event: definitions["events"];
  image: File | null;
}

interface FormItems {
  title: string;
  organizerId: string;
  facebook: string;
  registrationLink: string;
}

interface EventFormProps {
  event?: definitions["events"];
  onSave: ({ event, image }: OnSaveParams) => void;
  loading: boolean;
}

const EventForm = ({ event: initialEvent, onSave, loading, ...props }: EventFormProps) => {
  const { showToast } = useToast();
  const geocoder = new google.maps.Geocoder();
  const navigate = useNavigate();
  const { category } = useParams() as CategoryParams;
  const { currentUser } = useAuth();
  const { organization } = useOrganization({ ownerId: currentUser?.id });
  const [image, setImage] = useState<File | null>(null);
  const [localizations, setLocalizations] = useState<google.maps.GeocoderResult[]>([]);
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { isValid },
    control,
  } = useForm<FormItems>({
    mode: "onChange",
  });

  const [event, setEvent] = useState(
    initialEvent || {
      ...emptyEvent,
      category,
      userId: currentUser?.id || "",
      organizerId: organization?.id || "",
    },
  );

  const [imageSizeLimit, setImageSizeLimit] = useState(false);

  const isObedienceCompetition = event.type === "competition" && event.category === "obedience";

  const editorPropsDescription = useWysiwygEditorSlate({
    initialValue: event.description?.pl?.value,
    saveOnBlur: ({ value }) => {
      setEvent({ ...event, description: { pl: { value } } });
    },
    resetDuration: 2000,
  });

  const submit = (data: FormItems) => {
    onSave({
      event: { ...event, ...data, organizerId: data.organizerId || undefined, title: { pl: { value: data.title } } },
      image,
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "isOnline") {
      setEvent({ ...event, [e.target.name]: e.target.checked, place: null });
    } else {
      setEvent({ ...event, [e.target.name]: e.target.value });
    }
  };

  const handleChangeLocalization = (e: any, value: google.maps.GeocoderResult | null) => {
    setEvent({ ...event, place: value });
  };

  const getLocalizations = throttle((address: string) => {
    if (address) {
      geocoder.geocode({ address, region: "EU" }, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK && results) {
          setLocalizations(results);
        } else {
          console.log("Geocode was not successful for the following reason: " + status);
        }
      });
    }
  }, 1000);

  const handleClassChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEvent({ ...event, obedienceClass: { ...event.obedienceClass, [e.target.value]: e.target.checked } });
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    if (e.target.files[0].size > 2097152) {
      setImageSizeLimit(true);
      showToast(t("error.image-size-limit"), "error");
      setImage(null);
    } else {
      setImageSizeLimit(false);
      setImage(e.target.files[0]);
    }
  };

  const handleSelectType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isObedienceCompetition = e.target.value === "competition" && event.category === "obedience";
    setEvent({
      ...event,
      type: e.target.value as EventType,
      obedienceClass: isObedienceCompetition ? event.obedienceClass : null,
    });
  };

  const handlePublish = (isPublished: boolean) => {
    setEvent({ ...event, isPublished });
  };

  const imagePreviewUrl = image ? URL.createObjectURL(image) : event.imageUrl || FALLBACK_IMAGE;

  if (!currentUser) return null;

  return (
    <Box sx={{ position: "relative", mb: "85px" }}>
      <form autoComplete="off" noValidate {...props} onSubmit={handleSubmit(submit)}>
        <Card>
          <CardHeader title={t("event-form.event-information", { category: t(`${event.category}`) })} />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid container item xs={12} sm={6} alignItems="flex-end" spacing={3}>
                <Grid item xs={12} sm={6}>
                  <DateTimePicker
                    label={t("event-form.start-date")}
                    inputFormat="dd/MM/yyyy HH:mm"
                    value={event.startDate}
                    onChange={(date: Date | null) => {
                      if (date && isValidDate(date)) {
                        setEvent({ ...event, startDate: date.toISOString() });
                      }
                    }}
                    renderInput={(params: any) => <TextField {...params} />}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateTimePicker
                    label={t("event-form.end-date")}
                    inputFormat="dd/MM/yyyy HH:mm"
                    value={event.endDate}
                    minDateTime={new Date(event.startDate)}
                    onChange={(date: Date | null) => {
                      if (date && isValidDate(date)) {
                        setEvent({ ...event, endDate: date.toISOString() });
                      }
                    }}
                    renderInput={(params: any) => <TextField {...params} />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    select
                    name="type"
                    required
                    label={t("event-form.type")}
                    value={event.type}
                    onChange={handleSelectType}
                  >
                    {EVENT_TYPES[event.category].map((type) => (
                      <MenuItem key={type} value={type}>
                        {t(`${type}`, { count: 1 })}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ImageUploader>
                  <Image src={imagePreviewUrl} />
                  <label htmlFor="contained-button-file" style={{ alignSelf: "flex-end", marginTop: 8 }}>
                    <Input accept="image/*" id="contained-button-file" type="file" onChange={handleImageChange} />
                    <Button variant="contained" component="span" size="small" startIcon={<PhotoCamera />}>
                      {image || event.imageUrl ? t("change-photo") : t("add-photo")}
                    </Button>
                    {imageSizeLimit && (
                      <Typography variant="body2" color="error">
                        {t("error.image-size-limit")}
                      </Typography>
                    )}
                  </label>
                </ImageUploader>
              </Grid>
              {isObedienceCompetition && (
                <Grid item xs={12}>
                  <FormControl component="fieldset" variant="standard">
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!!(event.obedienceClass && event.obedienceClass["0"])}
                            value="0"
                            onChange={handleClassChange}
                            name="obedienceClass"
                          />
                        }
                        label={t("event-form.obedience-class-0")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!!(event.obedienceClass && event.obedienceClass["1"])}
                            value="1"
                            onChange={handleClassChange}
                            name="obedienceClass"
                          />
                        }
                        label={t("event-form.obedience-class-1")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!!(event.obedienceClass && event.obedienceClass["2"])}
                            value="2"
                            onChange={handleClassChange}
                            name="obedienceClass"
                          />
                        }
                        label={t("event-form.obedience-class-2")}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!!(event.obedienceClass && event.obedienceClass["3"])}
                            value="3"
                            onChange={handleClassChange}
                            name="obedienceClass"
                          />
                        }
                        label={t("event-form.obedience-class-3")}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12}>
                <FormTextField
                  name="title"
                  control={control}
                  label={t("event-form.title")}
                  placeholder={t("event-form.title")}
                  defaultValue={event.title.pl.value || ""}
                  rules={{
                    validate: (value: string) => !!value.trim() || t("event-form.error-no-empty-field"),
                  }}
                />
              </Grid>

              <Grid container item xs={12} spacing={2} alignItems="center">
                <Grid item xs={12} sm={9}>
                  <Autocomplete
                    id="place"
                    options={localizations}
                    filterOptions={(x) => x}
                    onChange={handleChangeLocalization}
                    onInputChange={(event, newInputValue) => {
                      getLocalizations(newInputValue);
                    }}
                    value={event.place}
                    fullWidth
                    disabled={event.isOnline}
                    autoHighlight
                    getOptionLabel={(option: google.maps.GeocoderResult) => option.formatted_address}
                    isOptionEqualToValue={(option, value) => option.formatted_address === value?.formatted_address}
                    renderOption={(props, option: google.maps.GeocoderResult) => (
                      <li {...props}>{option.formatted_address}</li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("event-form.address")}
                        placeholder={t("event-form.address")}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControlLabel
                    control={<Checkbox checked={event.isOnline} onChange={handleChange} name="isOnline" />}
                    label={t("event-form.is-online")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormTextField
                    name="facebook"
                    control={control}
                    label={t("event-form.facebook")}
                    placeholder={t("event-form.facebook-placeholder")}
                    defaultValue={event.facebook || ""}
                    rules={{
                      require: false,
                      validate: (value: string) =>
                        isURL(value, {
                          protocols: ["http", "https"],
                          require_protocol: true,
                        }) ||
                        !value ||
                        t("event-form.error-link"),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardHeader
            title={
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                {t("event-form.event-contact-information")}
                <Tooltip title={t("event-form.event-contact-information-tooltip")!}>
                  <Help color="primary" />
                </Tooltip>
              </Box>
            }
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormSelect
                  name="organizerId"
                  control={control}
                  label={t("event-form.organizer")}
                  defaultValue={event.organizerId}
                >
                  <MenuItem value={organization?.id || ""}>{organization?.name || ""}</MenuItem>
                </FormSelect>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardHeader title={t("event-form.registration")} />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <FormTextField
                    name="registrationLink"
                    control={control}
                    label={t("event-form.registration-link-placeholder")}
                    placeholder={t("event-form.registration-link-placeholder")}
                    defaultValue={event.registrationLink || ""}
                    rules={{
                      require: false,
                      validate: (value: string) =>
                        isURL(value, {
                          protocols: ["http", "https"],
                          require_protocol: true,
                        }) ||
                        !value ||
                        t("event-form.error-link"),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardHeader title={t("event-form.event-information-description")} />
          <Divider />
          <CardContent>
            <WysiwygEditorSlate
              {...editorPropsDescription}
              placeholder={t("event-form.information-description-placeholder")}
            />
          </CardContent>
        </Card>
        <ButtonsWrapper>
          <Button variant="outlined" disabled={loading} onClick={() => navigate("..")}>
            {t("cancel")}
          </Button>
          <SplitButton
            disabled={!isValid}
            loading={loading}
            handlePublish={handlePublish}
            isPublished={!!initialEvent?.isPublished}
          ></SplitButton>
        </ButtonsWrapper>
      </form>
    </Box>
  );
};

export default EventForm;
