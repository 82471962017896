import React, { ReactChild } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { Controller } from "react-hook-form";

type Props = {
  name: string;
  control: any;
  label: string;
  defaultValue?: any;
  children: ReactChild;
  rules?: any;
};

const FormSelect = ({ name, label, control, defaultValue, children, rules, ...props }: Props) => {
  const labelId = `${name}-label`;
  return (
    <FormControl {...props} fullWidth>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <Select labelId={labelId} label={label} onChange={onChange} value={value}>
            {children}
          </Select>
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
    </FormControl>
  );
};
export default FormSelect;
