import React from "react";
import { Box as OBox, Typography as OTypography, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const Wrapper = styled(OBox)(({ theme }) => ({
  backgroundColor: theme.palette.secondary["900"],
}));

const Box = styled(OBox)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(0)}`,
}));

const Typography = styled(OTypography)(({ theme }) => ({
  color: theme.palette.common.white,
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
    "&:before": {
      content: '"|"',
      marginRight: theme.spacing(1),
    },
  },
}));

const Copyright = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Container>
        <Box>
          <Typography>{t("copyright")}</Typography>
        </Box>
      </Container>
    </Wrapper>
  );
};

export default Copyright;
