import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { Typography, Grid, Button, Box, useMediaQuery, useTheme, Container } from "@mui/material";

import HeroImage from "../../images/hero8.svg";
import HeroImageSmall from "../../images/hero2.svg";

const HeroContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  position: "relative",
  zIndex: 2,
  padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
  [theme.breakpoints.up("sm")]: {
    padding: `${theme.spacing(8)} ${theme.spacing(2)}`,
  },
  [theme.breakpoints.up("md")]: {
    padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
    alignItems: "start",
    textAlign: "left",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  whiteSpace: "pre-line",
  fontSize: theme.typography.h4.fontSize,
  fontWeight: 500,
  [theme.breakpoints.up("sm")]: {
    fontSize: theme.typography.h3.fontSize,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: theme.typography.h3.fontSize,
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: theme.typography.h2.fontSize,
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: 72,
  },
}));

const ButtonsWrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "auto auto",
  gap: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(1),

    gridTemplateColumns: "auto",
    gridTemplateRows: "auto auto",
    justifyItems: "center",
  },
}));

const WrapperHero = styled("section")(() => ({
  position: "relative",
  overflow: "hidden",
}));

const HeroBackground = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  height: "calc(100vh - 64px)",
  maxHeight: 600,
  [theme.breakpoints.up("md")]: {
    maxHeight: 900,
  },
}));

const Image = styled("img")(({ theme }) => ({
  width: "90%",
  position: "absolute",
  bottom: 0,
  right: "-20%",
  [theme.breakpoints.up("sm")]: {
    width: "70%",
  },
  [theme.breakpoints.up("md")]: {
    left: 0,
    width: "170%",
    position: "relative",
  },
}));

const Hero = (): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"), { noSsr: true });

  return (
    <WrapperHero>
      <HeroBackground>
        <Container maxWidth="lg" sx={{ height: "100%" }}>
          <Grid
            container
            sx={{ height: matches ? "100%" : "" }}
            alignItems={matches ? "center" : "flex-start"}
            justifyContent="center"
          >
            <Grid item md={6}>
              <HeroContent>
                <Title component="h1" variant="h2" gutterBottom>
                  {t("hero.title")}
                </Title>
                {matches && (
                  <Typography variant="h6" paragraph sx={{ fontWeight: 300, mb: 4 }}>
                    {t("hero.subtitle")}
                  </Typography>
                )}
                <ButtonsWrapper>
                  <Link to="/wydarzenia">
                    <Button variant="contained" color="primary" size={matches ? "large" : "small"}>
                      {t("hero.see-events")}
                    </Button>
                  </Link>
                  <Link to="/dashboard">
                    <Button variant="outlined" size={matches ? "large" : "small"}>
                      {t("hero.add-event")}
                    </Button>
                  </Link>
                </ButtonsWrapper>
              </HeroContent>
            </Grid>
            <Grid item md={6}>
              <Image src={matches ? HeroImage : HeroImageSmall} />
            </Grid>
          </Grid>
        </Container>
      </HeroBackground>
    </WrapperHero>
  );
};

export default Hero;
