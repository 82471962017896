import React, { useCallback, useEffect, useState, useContext, createContext, ReactNode, ReactChild } from "react";
import { AlertColor } from "@mui/material/Alert";

import { Alert, Wrapper } from "./style";

interface Toast {
  text: ReactNode | string;
  type: AlertColor;
}

interface ToastContext {
  showToast: (text: ReactNode | string, type?: AlertColor) => void;
}

const initialContext: ToastContext = {
  showToast: () => {},
};

const ToastContext = createContext<ToastContext>(initialContext);

interface ToastProviderProps {
  children: ReactChild;
}

export const ToastProvider = ({ children }: ToastProviderProps) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  useEffect(() => {
    if (toasts.length > 0) {
      const timer = setTimeout(() => setToasts((toasts) => toasts.slice(1)), 3000);
      return () => clearTimeout(timer);
    }
  }, [toasts]);

  const showToast = useCallback(
    function (text: ReactNode | string, type?: AlertColor) {
      setToasts((toasts) => [...toasts, { text, type: type || "success" }]);
    },
    [setToasts],
  );

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {toasts.length > 0 && (
        <Wrapper>
          <div>
            {toasts.map((toast, idx) => (
              <Alert key={idx} severity={toast.type} elevation={6}>
                {toast.text}
              </Alert>
            ))}
          </div>
        </Wrapper>
      )}
    </ToastContext.Provider>
  );
};

export function useToast() {
  return useContext(ToastContext);
}
