import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import loader from "../../images/loader.gif";
// import loader from "../../images/480-3.gif";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const PageViewLoader = (): JSX.Element => {
  return (
    <Wrapper>
      {/* <img src={loader} alt="loading..." width={160} height={80} /> */}
      <img src={loader} alt="loading..." />
    </Wrapper>
  );
};

export default PageViewLoader;
