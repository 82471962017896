import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

import { getColorToRGBA } from "../../utils";

const Wrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.common.white,
  padding: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: theme.spacing(2),
  cursor: "pointer",
  transition: "0.1s",
  marginBottom: theme.spacing(2),
  width: "100%",

  "&:hover": {
    background: getColorToRGBA(theme.palette.primary.main, 0.2),
  },
}));

interface Props {
  category: string;
}
const EventCategory = ({ category }: Props) => {
  const { t } = useTranslation();
  return <Wrapper>{t(`${category}`)}</Wrapper>;
};

export default EventCategory;
