import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { useRoutes } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import { ThemeProvider as StyledThemeProvider } from "styled-components";

import { AuthProvider } from "./auth-provider-supabase";
import { ToastProvider } from "./components/toast";

import { useTracking } from "./hooks/use-tracking";
import theme from "./theme";
import routes from "./routes";
import LocalizationProvider from "./localization-provider";

import "./index.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
});

function App(): JSX.Element {
  const routing = useRoutes(routes);
  useTracking();

  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <StyledThemeProvider theme={theme}>
            <LocalizationProvider>
              <ToastProvider>
                <AuthProvider>{routing}</AuthProvider>
              </ToastProvider>
            </LocalizationProvider>
          </StyledThemeProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  );
}

export default App;
