import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import PageViewLoader from "../../components/page-view-loader";
import EventForm from "../../components/event-form";
import Error from "../../components/error";
import { definitions } from "../../types/supabase";
import { useEvent } from "./use-event";
import { useUpdateEvent } from "./use-update-event";

interface OnSaveParams {
  event: definitions["events"];
  image: File | null;
}

const MyEventsEdit = () => {
  const { t } = useTranslation();
  const { eventId } = useParams();
  const { event, isLoading: isLoadingEvent, error } = useEvent({ id: eventId });
  const { updateEvent, isLoading } = useUpdateEvent();

  const onSave = ({ event, image }: OnSaveParams) => {
    updateEvent({ event, image });
  };
  console.log({ error });

  if (isLoadingEvent && !event) return <PageViewLoader />;
  if (error && !event) return <Error error={error} />;
  if (!event) return null;

  return (
    <>
      <Helmet>
        <title>{t("page-title.my-events-edit")}</title>
      </Helmet>
      <EventForm event={event} onSave={onSave} loading={isLoading} />
    </>
  );
};

export default MyEventsEdit;
