import React from "react";
import { Helmet } from "react-helmet";

import Hero from "../../components/hero";
// import EventTypesSlider from "../../components/event-types-slider";
import ForOrganizers from "../../components/for-organizers";
import ForParticipants from "../../components/for-participants";

const Home = (): JSX.Element => {
  return (
    <>
      <Helmet>
        ‍<title>Dogspace</title>‍
        <meta name="description" content="Wydarzenia kynologiczne" />
        <meta property="og:url" content="https://www.dogspace.pl" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Dogspace" />
        <meta property="og:description" content="Psie wydarzenia - agility, obedience, wystawy" />
        <meta
          property="og:image"
          content="https://kiikelnxfvlhaoitbazj.supabase.in/storage/v1/object/public/dogspace/assets/preview.png"
        />
      </Helmet>
      <Hero />
      <ForOrganizers />
      <ForParticipants />
      {/* <EventTypesSlider /> */}
    </>
  );
};

export default Home;
