import enLocale from "date-fns/locale/en-US";
import plLocale from "date-fns/locale/pl";
import { Locale as LocaleDateFns } from "date-fns";

interface Locale {
  [locale: string]: LocaleDateFns;
}

export const locale: Locale = {
  en: enLocale,
  pl: plLocale,
};
