import React, { ReactChild } from "react";
import { useTranslation } from "react-i18next";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import OLocalizationProvider from "@mui/lab/LocalizationProvider";
import enLocale from "date-fns/locale/en-US";
import plLocale from "date-fns/locale/pl";
import { Locale as LocaleDateFns } from "date-fns";

interface Locale {
  [locale: string]: LocaleDateFns;
}

export const locale: Locale = {
  en: enLocale,
  pl: plLocale,
};

function LocalizationProvider({ children }: { children: ReactChild }): JSX.Element {
  const { i18n } = useTranslation();

  return (
    <OLocalizationProvider dateAdapter={AdapterDateFns} locale={locale[i18n.language]}>
      {children}
    </OLocalizationProvider>
  );
}

export default LocalizationProvider;
