import { useMutation, useQueryClient } from "react-query";

import supabase from "../../supabase";
import { useToast } from "../../components/toast";
import { definitions } from "../../types/supabase";
import { useAuth } from "../../auth-provider-supabase";

interface CreateOrganizationParams {
  ownerId: string;
}

const createOrganizationSupabase = async ({ ownerId }: CreateOrganizationParams) => {
  const { data, error } = await supabase.from<definitions["organizations"]>("organizations").insert({ ownerId });

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export function useCreateOrganization() {
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const { currentUser } = useAuth();

  const { mutate, isLoading, data, error } = useMutation(createOrganizationSupabase, {
    onSuccess: (data) => {
      if (data) {
        queryClient.setQueryData(["myOrganization"], data);
      }
    },
    onError: (error: Error) => {
      showToast(error.message, "error");
    },
  });

  const createOrganization = () => {
    if (!currentUser?.id) return;
    mutate({ ownerId: currentUser.id });
  };

  return { createOrganization, isLoading, data, error };
}
