import React, { useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { Box, Button, Drawer, Hidden, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAuth } from "../../auth-provider-supabase";
import { route } from "../../routes";

import { LINKS } from "../topbar";
import NavLink from "../nav-link";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  gridAutoRows: "auto",
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
}));

interface SidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const Sidebar = ({ onMobileClose, openMobile }: SidebarProps): JSX.Element => {
  const location = useLocation();
  const { t } = useTranslation();
  const { currentUser } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Wrapper>
      {LINKS.map(({ name, href }) => (
        <NavLink to={href} key={name}>
          <Typography variant="h6" color="inherit" sx={{ fontWeight: 400 }}>
            {t(`${name}`, { count: 2 })}
          </Typography>
        </NavLink>
      ))}
      {currentUser ? (
        <Link to={route.dashboard.name}>
          <Button color="primary" variant="contained">
            {t("my-account")}
          </Button>
        </Link>
      ) : (
        <>
          <Link to="/login">
            <Button variant="outlined">{t("login")}</Button>
          </Link>
          <Link to={route.signup.name}>
            <Button color="primary" variant="contained">
              {t("register")}
            </Button>
          </Link>
        </>
      )}
    </Wrapper>
  );

  return (
    <>
      <Hidden mdUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            style: {
              width: 256,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default Sidebar;
