import React from "react";
import { NavLink as RouterLink, matchPath, useLocation } from "react-router-dom";
import { Button, ListItem } from "@mui/material";
import { useTranslation } from "react-i18next";

interface NavItemProps {
  href: string;
  icon: any;
  title: string;
}

const NavItem = ({ href, icon: Icon, title }: NavItemProps): JSX.Element => {
  const location = useLocation();
  const { t } = useTranslation();

  const active = href
    ? !!matchPath(
        {
          path: href,
          end: false,
        },
        location.pathname,
      )
    : false;

  return (
    <ListItem
      disableGutters
      style={{
        display: "flex",
      }}
    >
      <Button
        component={RouterLink}
        style={{
          color: "text.secondary",
          justifyContent: "flex-start",
          letterSpacing: 0,
          padding: 8,
          textTransform: "none",
          width: "100%",
          ...(active && {
            color: "primary.main",
          }),
        }}
        to={href}
      >
        {Icon && <Icon size="20" style={{ marginRight: 16 }} />}
        <span>{t(title)}</span>
      </Button>
    </ListItem>
  );
};

export default NavItem;
