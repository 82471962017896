import escapeHtml from "escape-html";
import { Node, Text } from "slate";

import { BlockFormat } from "./types";

const serialize = (node: Node): string => {
  // if text node, stop recursion
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (node.italic) {
      string = `<em>${string}</em>`;
    }
    if (node.underline) {
      string = `<u>${string}</u>`;
    }
    return string;
  }

  // if non-leaf node, recurse on children and then process self
  const children = node?.children?.map((n) => serialize(n)).join("");

  switch (node.type) {
    case BlockFormat.P:
      return !children ? `<br />` : `<p>${children}</p>`;
    case BlockFormat.H1:
      return `<h1>${children}</h1>`;
    case BlockFormat.H1:
      return `<h1>${children}</h1>`;
    case BlockFormat.H2:
      return `<h2>${children}</h2>`;
    case BlockFormat.UL:
      return `<ul>${children}</ul>`;
    case BlockFormat.OL:
      return `<ol>${children}</ol>`;
    case BlockFormat.LI:
      return `<li>${children}</li>`;
    case "hr":
      return `<hr />${children}`;
    case "br":
      return "\n";
    case "link": {
      if (!children) return "";
      const url = escapeHtml(node.url as string);
      const targetStr = node.openInNewTab ? 'target="_blank" rel="noopener noreferrer"' : "";
      return `<a href="${url}" ${targetStr}>${children}</a>`;
    }
    default:
      return children;
  }
};

export const serializeToHtml = (value: Node[]) => {
  const html = serialize({ children: value } as Node);
  return html === "\n" ? "" : html;
};
