export enum MarksFormat {
  BOLD = "bold",
  ITALIC = "italic",
  UNDERLINE = "underline",
}

export enum BlockFormat {
  H1 = "heading-one",
  H2 = "heading-two",
  UL = "unordered-list",
  OL = "ordered-list",
  LI = "list-item",
  P = "paragraph",
}

export type ElementType = BlockFormat | "hr" | "link";
