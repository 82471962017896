import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useTranslation } from "react-i18next";

interface Props {
  error: Error;
}

export default function Error({ error }: Props) {
  const { t } = useTranslation();
  return (
    <Alert severity="error">
      <AlertTitle>{t("error.something-went-wrong")}</AlertTitle>
      {error.message}
    </Alert>
  );
}
