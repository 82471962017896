import { useMutation, useQueryClient } from "react-query";
import supabase from "../supabase";

const logoutSupabase = async () => {
  const { error } = await supabase.auth.signOut();

  if (error) {
    throw error;
  }
};

export function useLogout() {
  const queryClient = useQueryClient();
  const { mutate, isLoading, data, error } = useMutation(() => logoutSupabase(), {
    onSuccess: () => {
      queryClient.removeQueries();
    },
  });

  return { logout: mutate, isLoading, data, error };
}
