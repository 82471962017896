import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";

const NavLink = styled(Link)(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.text.primary,
  transition: "0.1s ease-in-out",

  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

export default NavLink;
