import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { PostgrestError } from "@supabase/supabase-js";

import supabase from "../supabase";
import { useToast } from "../components/toast";
import { route } from "../routes";

interface User {
  firstName?: string;
  lastName?: string;
  username: string;
  email: string;
  password: string;
  isOrganizer: boolean;
}

const signupSupabase = async (user: User) => {
  const { username, email, password } = user;
  // Check if email or username exist
  const { data: userWithEmail } = await supabase.from("users").select("*").eq("email", email).single();
  const { data: userWithUsername } = await supabase.from("users").select("*").eq("username", username).single();

  if (userWithEmail) {
    throw new Error("error-signup-email");
  }

  if (userWithUsername) {
    throw new Error("error-signup-username");
  }

  const { data, error: signUpError } = await supabase.auth.signUp({
    email,
    password,
  });

  if (signUpError) {
    throw signUpError;
  }

  return { data, user };
};

export function useSignup() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showToast } = useToast();

  const { mutate, isLoading, data, error } = useMutation((user: User) => signupSupabase(user), {
    onError: (error: PostgrestError) => {
      showToast(t(error.message), "error");
      showToast(error.message, "error");
    },
    onSuccess: async ({ data, user }: { data: any; user: User }) => {
      const { firstName, lastName, username, email, isOrganizer } = user;

      const { data: insertData, error: insertError } = await supabase.from("users").insert({
        firstName,
        lastName,
        username,
        email,
        isOrganizer,
        id: data?.user?.id,
      });

      if (insertError) {
        throw insertError;
      }
      navigate(route.dashboard.name);

      return insertData;
    },
  });

  const signup = useCallback(
    (user: User) => {
      mutate(user);
    },
    [mutate],
  );

  return { signup, isLoading, data, error };
}
