import { useQuery } from "react-query";

import supabase from "../../supabase";
import { definitions } from "../../types/supabase";
import { EventType, PolandVoivodeship } from "../../types";
import { Event } from "../../components/event-card";

interface Params {
  types: EventType[];
  category: definitions["events"]["category"];
  voivodeships: PolandVoivodeship[];
  isOnline: boolean;
}

const getEventsSupabase = async ({ types, category, voivodeships, isOnline }: Params) => {
  const now = new Date();
  const location = isOnline
    ? `voivodeship.in.(${voivodeships}),isOnline.is.${isOnline}`
    : `voivodeship.in.(${voivodeships})`;

  const { data, error } = await supabase
    .from<Event>("events")
    .select("id, imageUrl, title, eventSlug, type, startDate, place, isOnline, category, isPublished")
    .order("startDate")
    .eq("category", category)
    .is("isPublished", true)
    .in("type", types)
    .or(location)
    .gte("startDate", now.toISOString());

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export function useEvents({ types, category, voivodeships, isOnline }: Params) {
  const { isLoading, data, error } = useQuery<Event[] | null, Error>(
    ["events", types, category, voivodeships, isOnline],
    () => getEventsSupabase({ types, category, voivodeships, isOnline }),
    {
      onSuccess: () => {},
    },
  );

  return { events: data, isLoading, error };
}
