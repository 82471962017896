import { useQuery } from "react-query";

import supabase from "../../supabase";
import { definitions } from "../../types/supabase";

const getEventSupabase = async ({ eventSlug }: { eventSlug?: string }) => {
  if (!eventSlug) return;
  const { data, error } = await supabase
    .from<definitions["events"]>("events")
    .select()
    .eq("eventSlug", eventSlug)
    .single();
  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export function useEvent({ eventSlug }: { eventSlug?: string }) {
  const { isLoading, data, error } = useQuery(["event", eventSlug], () => getEventSupabase({ eventSlug }));

  return { event: data, isLoading, error };
}
