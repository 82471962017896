import React from "react";
import { Box, Typography, Container, Grid } from "@mui/material";
import BaseList from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import CardMedia from "@mui/material/CardMedia";
import CheckIcon from "@mui/icons-material/Check";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import ORGANIZER from "../../images/organizer.svg";

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundPosition: "center",
  backgroundSize: "cover",
  position: "relative",
  paddingTop: theme.spacing(10),
  [theme.breakpoints.up("md")]: {
    padding: `${theme.spacing(12)} ${theme.spacing(0)}`,
  },
}));

const List = styled(BaseList)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
}));

const FOR_ORGANIZERS = ["for-organizers-1", "for-organizers-2", "for-organizers-3"];

const ForOrganizers = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Container>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" color="primary" sx={{ fontWeight: 800 }}>
              {t("home.for-organizers-title")}
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 900, mb: 4 }} gutterBottom>
              {t("home.for-organizers-subtitle")}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t("home.for-organizers-description")}
            </Typography>
            <List>
              {FOR_ORGANIZERS.map((item) => (
                <ListItem key={item} disablePadding>
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={t(`home.${item}`)} sx={{ fontWeight: 500 }} />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CardMedia component="img" image={ORGANIZER} />
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default ForOrganizers;
