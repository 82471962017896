import React from "react";
import { styled } from "@mui/material/styles";

import { Button, ButtonProps } from "@mui/material";
import OCircularProgress from "@mui/material/CircularProgress";

const CircularProgress = styled(OCircularProgress)(({ theme }) => ({
  color: theme.palette.grey[500],
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: -12,
  marginLeft: -12,
}));

interface ButtonLoadingProps extends ButtonProps {
  children: string;
  loading: boolean;
}

const ButtonLoading = ({ children, loading, ...props }: ButtonLoadingProps): JSX.Element => {
  return (
    <Button {...props}>
      {children}
      {loading && <CircularProgress size={23} />}
    </Button>
  );
};
export default ButtonLoading;
