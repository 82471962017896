import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useTracking = () => {
  const location = useLocation();

  useEffect(() => {
    window.gtag("set", "page", location.pathname + location.search);
    window.gtag("event", "page_view");
  }, [location]);
};
