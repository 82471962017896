import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Avatar, Box, Card, CardContent, Typography, Divider, CardActions, Button } from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";

import { definitions } from "../../types/supabase";
import { useToast } from "../toast";

import { useUpdateOrganizationImage } from "./use-update-organization-image";
import { useAuth } from "../../auth-provider-supabase";

interface Props {
  organization: definitions["organizations"];
}

const Input = styled("input")({
  display: "none",
});

const ImageUploader = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
});

const OrganizationProfile = ({ organization }: Props) => {
  const { t } = useTranslation();
  const { userId } = useAuth();
  const { showToast } = useToast();
  const [image, setImage] = useState<File | null>(null);
  const [imageSizeLimit, setImageSizeLimit] = useState(false);
  const { id, imageUrl, name } = organization;
  const imagePreviewUrl = image ? URL.createObjectURL(image) : imageUrl || "/static/images/avatars/avatar_6.png";

  const { updateOrganization } = useUpdateOrganizationImage();

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!userId) return;
    if (!e.target.files) return;
    if (e.target.files[0].size > 2097152) {
      setImageSizeLimit(true);
      showToast(t("error.image-size-limit"), "error");
      setImage(null);
    } else {
      updateOrganization({ userId, organizationId: id, image: e.target.files[0] });
      setImageSizeLimit(false);
      setImage(e.target.files[0]);
    }
  };

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ImageUploader>
            <Avatar
              src={imagePreviewUrl}
              sx={{
                height: 100,
                width: 100,
                mb: 2,
              }}
            />
          </ImageUploader>
          {name && (
            <Typography color="textPrimary" gutterBottom variant="h5">
              {name}
            </Typography>
          )}
        </Box>
      </CardContent>
      <Divider />
      <CardActions style={{ justifyContent: "center" }}>
        <label htmlFor="contained-button-file">
          <Input accept="image/*" id="contained-button-file" type="file" onChange={handleImageChange} />
          <Button variant="text" component="span" size="small" startIcon={<PhotoCamera />}>
            {image || imageUrl ? t("change-photo") : t("add-photo")}
          </Button>
          {imageSizeLimit && (
            <Typography variant="body2" color="error">
              {t("error.image-size-limit")}
            </Typography>
          )}
        </label>
      </CardActions>
    </Card>
  );
};

export default OrganizationProfile;
