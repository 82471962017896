import React from "react";
import { Link as OLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { Typography, Box, Grid } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useForm, SubmitHandler } from "react-hook-form";

import { useSignup } from "../../auth-provider-supabase";
import ButtonLoading from "../../components/button-loading";
import FormTextField from "../../components/form-text-field";
import FormRadioGroup from "../../components/form-radio-group";
import { EMAIL_REGEX, USERNAME_REGEX } from "../../constants";

const Wrapper = styled(Box)({
  textAlign: "center",
  maxWidth: 500,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "0px auto",
  minHeight: "calc(100vh - 81px)",
});

const GridItem = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const LinkWrapper = styled(Typography)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const Link = styled(OLink)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginLeft: theme.spacing(1),

  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

interface FormItems {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  isOrganizer: boolean;
}

const SignUpForm = (): JSX.Element => {
  const { t } = useTranslation();
  const { signup, isLoading } = useSignup();

  const {
    handleSubmit,
    watch,
    formState: { isValid },
    control,
  } = useForm<FormItems>({ mode: "onChange" });

  const onSubmit: SubmitHandler<FormItems> = ({ username, email, password, isOrganizer }) => {
    signup({ username, email, password, isOrganizer });
  };

  const watchPassword = watch("password");

  return (
    <Wrapper>
      <Box>
        <Box sx={{ p: 1, mb: 2 }}>
          <Typography variant="h3" sx={{ mb: 2 }}>
            {t("signup")}
          </Typography>
          <Typography color="textSecondary">{t("signup-page.subtitle")}</Typography>
        </Box>
        <Grid container>
          <GridItem item xs={12}>
            <FormRadioGroup
              name="isOrganizer"
              control={control}
              label={t("signup-page.account-type")}
              defaultValue={true}
              options={[
                { label: t("signup-page.organizer"), value: true },
                { label: t("signup-page.owner"), value: false, disabled: true },
              ]}
              rules={{
                required: true,
              }}
              row
            />
          </GridItem>
          <GridItem item xs={12}>
            <FormTextField
              variant="outlined"
              name="username"
              control={control}
              label={t("signup-page.username")}
              placeholder={t("signup-page.username")}
              rules={{
                required: t("signup-page.error-no-empty-field"),
                pattern: {
                  value: USERNAME_REGEX,
                  message: t("signup-page.error-username-pattern"),
                },
              }}
            />
          </GridItem>
          <GridItem item xs={12}>
            <FormTextField
              variant="outlined"
              name="email"
              control={control}
              label={t("signup-page.email")}
              placeholder={t("signup-page.email")}
              rules={{
                required: t("signup-page.error-no-empty-field"),
                pattern: {
                  value: EMAIL_REGEX,
                  message: t("signup-page.error-email-pattern"),
                },
              }}
            />
          </GridItem>
          <GridItem item xs={12}>
            <FormTextField
              variant="outlined"
              type="password"
              name="password"
              control={control}
              label={t("signup-page.password")}
              placeholder={t("signup-page.password")}
              rules={{
                required: t("signup-page.error-no-empty-field"),
                minLength: {
                  value: 6,
                  message: t("signup-page.error-password-length"),
                },
              }}
            />
          </GridItem>
          <GridItem item xs={12}>
            <FormTextField
              variant="outlined"
              type="password"
              name="passwordConfirmation"
              control={control}
              label={t("signup-page.repeat-password")}
              placeholder={t("signup-page.repeat-password")}
              rules={{
                required: t("signup-page.error-no-empty-field"),
                validate: (value: string) => value === watchPassword || t("signup-page.error-password-repeat"),
              }}
            />
          </GridItem>
          <GridItem item xs={12}>
            <ButtonLoading
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              type="submit"
              disabled={!isValid || isLoading}
              loading={isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              {t("signup")}
            </ButtonLoading>
          </GridItem>
          <GridItem item xs={12}>
            <LinkWrapper variant="subtitle1" color="textSecondary">
              {t("signup-page.login-redirect")}
              <Link to="/login">
                <span>{t("login")}</span>
                <ArrowForwardIcon />
              </Link>
            </LinkWrapper>
          </GridItem>
        </Grid>
      </Box>
    </Wrapper>
  );
};
export default SignUpForm;
