import React from "react";
import { Helmet } from "react-helmet";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

import EventCategory from "../../components/event-category";
import PageContentWrapper from "../../components/page-content-wrapper";

const Wrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
}));

const EVENT_CATEGORIES = ["agility", "obedience", "dogfrisbee", "puller"];

const Events = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        ‍<title>{t("events")} | Dogspace</title>‍
      </Helmet>
      <PageContentWrapper>
        <Wrapper>
          <Typography variant="h4" sx={{ mb: 4 }}>
            {t("events-list.select-category")}
          </Typography>
          {EVENT_CATEGORIES.map((category) => (
            <Link key={category} to={`${category}`} style={{ width: "100%" }}>
              <EventCategory category={category} />
            </Link>
          ))}
        </Wrapper>
      </PageContentWrapper>
    </>
  );
};

export default Events;
