import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname, search } = useLocation();

  useEffect(() => {
    const layout = document.getElementById("layout");
    if (layout) {
      layout.scrollTop = 0;
    }
  }, [pathname, search]);

  return null;
}
