import React from "react";
import { Navigate } from "react-router-dom";

import DashboardLayout from "./components/layout-dashboard";
import Layout from "./components/layout";
import PrivateRoute from "./components/private-route";
import Contact from "./pages/contact";
import Dashboard from "./pages/dashboard";
import Home from "./pages/home";
import Login from "./pages/login";
import Profile from "./pages/profile";
import ResetPassword from "./pages/reset-password";
import EventDetails from "./pages/event-details";
import Events from "./pages/events";
import EventsList from "./pages/events-list";
import MyEvents from "./pages/my-events";
import MyEventsAdd from "./pages/my-events-add";
import MyEventsEdit from "./pages/my-events-edit";
import MyOrganization from "./pages/my-organization";
import PrivacyPolicy from "./pages/privacy-policy";
import SignUp from "./pages/sign-up";
import NotFound from "./pages/not-found";

export const route = {
  breeders: {
    name: "breeders",
  },
  events: {
    name: "/wydarzenia",
    event: {
      name: "/wydarzenia/:category/:eventSlug",
    },
  },
  home: {
    name: "/",
  },
  login: {
    name: "/login",
  },
  resetPassword: {
    name: "/reset-password",
  },
  signup: {
    name: "/rejestracja",
  },
  dashboard: {
    name: "/dashboard",
    myEvents: {
      name: "/dashboard/moje-wydarzenia",
      add: {
        name: "/dashboard/moje-wydarzenia/dodaj",
      },
      edit: {
        event: {
          name: "/dashboard/moje-wydarzenia/edytuj/:eventId",
        },
      },
    },
    myOrganization: {
      name: "/dashboard/moja-organizacja",
    },
    profile: {
      name: "/dashboard/profil",
    },
  },
};

const routes = [
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "", element: <Home /> },
      { path: "kontakt", element: <Contact /> },
      { path: "login", element: <Login /> },
      { path: "rejestracja", element: <SignUp /> },
      { path: "reset-password", element: <ResetPassword /> },
      { path: "polityka-prywatnosci", element: <PrivacyPolicy /> },
      {
        path: "wydarzenia",
        children: [
          { path: "", element: <Events /> },
          { path: ":category", element: <EventsList /> },
          { path: ":category/:eventSlug", element: <EventDetails /> },
        ],
      },
      { path: "404", element: <NotFound /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        ),
      },
      {
        path: "profil",
        element: (
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        ),
      },
      {
        path: "moje-wydarzenia",
        children: [
          {
            path: "",
            element: (
              <PrivateRoute>
                <MyEvents />
              </PrivateRoute>
            ),
          },
          {
            path: "dodaj/:category",
            element: (
              <PrivateRoute>
                <MyEventsAdd />
              </PrivateRoute>
            ),
          },
          {
            path: "edytuj/:eventId",
            element: (
              <PrivateRoute>
                <MyEventsEdit />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        path: "moja-organizacja",
        children: [
          {
            path: "",
            element: (
              <PrivateRoute>
                <MyOrganization />
              </PrivateRoute>
            ),
          },
        ],
      },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;
