import React from "react";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";
import { Card as OCard, CardContent as OCardContent, Typography, IconButton } from "@mui/material/";
import Button from "@mui/material/Button";
import { Room, Computer, Email, Link, Facebook, Phone } from "@mui/icons-material";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { definitions } from "../../types/supabase";
import { locale } from "../../utils";

import ItemWithIcon from "../../components/item-with-icon";

const Card = styled(OCard)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
}));

const CardContent = styled(OCardContent)(({ theme }) => ({
  "&:last-child": {
    padding: theme.spacing(2),
  },
}));

const Title = styled(Typography)({
  fontSize: 14,
});

interface Props {
  event: definitions["events"];
}

const EventDetailsHighlights = ({
  event: { startDate, endDate, isOnline, email, www, facebook, phone, registrationLink, place },
}: Props): JSX.Element => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Card>
        <CardContent>
          <Box sx={{ mb: 4 }}>
            <Title color="textSecondary">{t("start-date")}</Title>
            <Typography variant="h6" component="h4" gutterBottom>
              {format(parseISO(startDate), "dd MMMM yyyy, HH:mm", { locale: locale[i18n.language] })}
            </Typography>
            <Title color="textSecondary">{t("end-date")}</Title>
            <Typography variant="h6" component="h2" gutterBottom>
              {format(parseISO(endDate), "dd MMMM yyyy, HH:mm", { locale: locale[i18n.language] })}
            </Typography>
          </Box>
          {phone && <ItemWithIcon icon={<Phone color="primary" />}>{phone}</ItemWithIcon>}
          {email && <ItemWithIcon icon={<Email color="primary" />}>{email}</ItemWithIcon>}
          {place && <ItemWithIcon icon={<Room color="primary" />}>{place.formatted_address}</ItemWithIcon>}
          {isOnline && <ItemWithIcon icon={<Computer color="primary" />}>{t("online-event")}</ItemWithIcon>}
        </CardContent>
      </Card>

      {(facebook || www) && (
        <Card sx={{ mt: 2 }}>
          <CardContent>
            {www && (
              <ItemWithIcon
                icon={
                  <IconButton href={www} target="_blank" rel="noreferrer, noopener">
                    <Link />
                  </IconButton>
                }
              >
                {t("event-details.www")}
              </ItemWithIcon>
            )}
            {facebook && (
              <ItemWithIcon
                icon={
                  <IconButton href={facebook} target="_blank" rel="noreferrer, noopener">
                    <Facebook />
                  </IconButton>
                }
              >
                {t("event-details.facebook-event")}
              </ItemWithIcon>
            )}
          </CardContent>
        </Card>
      )}
      {registrationLink && (
        <Button
          variant="contained"
          size="large"
          sx={{ mt: 2, width: "100%" }}
          href={registrationLink}
          target="_blank"
          rel="noreferrer, noopener"
        >
          {t("event-details.register-for-event")}
        </Button>
      )}
    </>
  );
};

export default EventDetailsHighlights;
