import { useQuery } from "react-query";

import supabase from "../../supabase";
import { useAuth } from "../../auth-provider-supabase";
import { definitions } from "../../types/supabase";

const getMyOrganizationSupabase = async ({ userId }: { userId?: string }) => {
  const { data, error } = await supabase
    .from<definitions["organizations"]>("organizations")
    .select()
    .eq("ownerId", userId);
  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export function useMyOrganization() {
  const { currentUser } = useAuth();
  const { isLoading, data, error, isError, refetch } = useQuery(
    "myOrganization",
    () => getMyOrganizationSupabase({ userId: currentUser?.id }),
    { enabled: !!currentUser },
  );

  const organization = data?.find((organization) => organization.ownerId === currentUser?.id);
  return { organization, isLoading, error, isError, refetch };
}
