import { useMutation, useQueryClient } from "react-query";

import supabase from "../../supabase";
import { useToast } from "../../components/toast";
import { useTranslation } from "react-i18next";
import { definitions } from "../../types/supabase";

interface UpdateEventParams {
  eventId: string;
  isPublished: boolean;
}

const updateEventSupabase = async ({ eventId, isPublished }: UpdateEventParams) => {
  const { data, error } = await supabase
    .from<definitions["events"]>("events")
    .update({ isPublished })
    .match({ id: eventId })
    .single();

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export function useUpdateEventVisibility() {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const queryClient = useQueryClient();

  const { mutate, isLoading, data, error } = useMutation(updateEventSupabase, {
    onSuccess: (data) => {
      if (data) {
        const prevData = queryClient.getQueryData<definitions["events"][]>(["myEvents"]);
        const newData = prevData?.map((event) => (event.id === data.id ? { ...event, ...data } : event));
        queryClient.setQueryData(["myEvents"], newData);
        queryClient.setQueryData(["myEventEdit", data.id], data);
      }
      showToast(t("my-events.event-updated"), "success");
    },
    onError: (error: Error) => {
      showToast(error.message, "error");
    },
  });

  const updateEvent = ({ eventId, isPublished }: UpdateEventParams) => {
    mutate({ eventId, isPublished });
  };

  return { updateEvent, isLoading, data, error };
}
