import { jsx } from "slate-hyperscript";
import { Node as SlateNode, Text, Element } from "slate";
import isHtml from "is-html";

import { BlockFormat } from "./types";
import { EmptyDocument } from "./utils/empty-document";

interface LeafAtributes {
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
}

/**
 * deserializeLeaf handles the case when leaf tags (STRONG, EM or U)
 * have other elements (for example link) as a child. In such case the wrapping leaf is removed.
 * see following related issues:
 * https://github.com/ianstormtaylor/slate/issues/3350https://github.com/udecode/plate/pull/83
 */
const deserializeLeaf = (
  attrs: LeafAtributes,
  children: SlateNode[],
  parentElement?: string,
): SlateNode[] | SlateNode => {
  // Leaf element can't have "BODY" as a parent
  if (parentElement === "BODY") {
    return jsx("element", { type: BlockFormat.P }, deserializeLeaf(attrs, children));
  }

  return children.reduce<SlateNode[]>((arr, child) => {
    if (!child) return arr;
    if (Element.isElement(child)) {
      arr.push(child);
    } else {
      arr.push(jsx("text", attrs, child));
    }
    return arr;
  }, []);
};

interface DeserializeParams {
  element: HTMLElement | ChildNode;
  parentElement?: string;
}

export const deserialize = ({ element, parentElement }: DeserializeParams): any => {
  if (element.nodeType === Node.TEXT_NODE) {
    const text = element.textContent;
    if (parentElement === "BODY") {
      if (text === "\n") return null;
      return jsx("element", { type: BlockFormat.P }, [{ text }]);
    }
    return text;
  } else if (element.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  let children = Array.from(element.childNodes).map((node) =>
    deserialize({ element: node, parentElement: element.nodeName }),
  );

  if (children.length === 0) {
    children = [{ text: "" }];
  }

  if (element instanceof HTMLElement) {
    switch (element.nodeName) {
      case "BODY":
        return jsx("fragment", {}, children);
      case "BR":
        return "\n";
      // return parentElement === "BODY" ? jsx("element", { type: BlockFormat.P }, [{ text: "" }]) : "\n";
      case "P":
        return jsx("element", { type: BlockFormat.P }, children);
      case "DIV":
        return jsx("element", { type: BlockFormat.P }, children);
      case "H1":
        return jsx("element", { type: BlockFormat.H1 }, children);
      case "H2":
        return jsx("element", { type: BlockFormat.H2 }, children);
      case "LI":
        return jsx("element", { type: BlockFormat.LI }, children);
      case "UL":
        return jsx("element", { type: BlockFormat.UL }, children);
      case "OL":
        return jsx("element", { type: BlockFormat.OL }, children);
      case "HR":
        return jsx("element", { type: "hr", children });
      case "STRONG":
      case "B":
        return deserializeLeaf({ bold: true }, children, parentElement);
      case "EM":
        return deserializeLeaf({ italic: true }, children, parentElement);
      case "U":
        return deserializeLeaf({ underline: true }, children, parentElement);
      case "A":
        return jsx(
          "element",
          {
            type: "link",
            url: element.getAttribute("href"),
            openInNewTab: element.getAttribute("target") === "_blank",
          },
          children,
        );
      default:
        return element.textContent;
    }
  }
};

const normalize = (node: SlateNode) => {
  if (Text.isText(node)) {
    return;
  }

  // Ensure that block and inline nodes have at least one text child.
  if (Element.isElement(node) && node.children.length === 0) {
    node.children.push({ text: "" });
    return;
  }
};

export const deserializeFromHtml = (html: string | null): SlateNode[] => {
  if (!html || html.trim().length === 0) {
    return EmptyDocument;
  }

  // if the string is not wrapped in any HTML tag wrap it in paragraph
  if (!isHtml(html)) {
    return [
      {
        type: "paragraph",
        children: [
          {
            text: html,
          },
        ],
      },
    ];
  }

  // parse html into a DOM document
  const document = new DOMParser().parseFromString(html, "text/html");

  // deserialize DOM document into an array of nodes
  const nodes: SlateNode[] = deserialize({ element: document.body });

  // normalize nodes to Slate compatible format
  nodes.forEach((node) => normalize(node));

  return nodes;
};
