import { useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";

import { useToast } from "../../components/toast";
import supabase from "../../supabase";
import { definitions } from "../../types/supabase";

interface UpdateOrganizationParams {
  userId: string;
  organizationId: string;
  image: File;
}

const updateOrganizationImageSupabase = async ({ userId, organizationId, image }: UpdateOrganizationParams) => {
  await supabase.storage.from("organizations").remove([`images/${userId}/${organizationId}/logo`]);

  const { data: uploadData, error: uploadError } = await supabase.storage
    .from("organizations")
    .upload(`images/${userId}/${organizationId}/logo`, image);

  if (uploadError) {
    throw new Error(uploadError.message);
  }

  const imageUrl = uploadData && `${process.env.REACT_APP_STORAGE_PUBLIC_BASE_URL}/${uploadData.Key}`;

  if (!imageUrl) return;

  const { data: eventUpdateData, error: eventUpdateError } = await supabase
    .from<definitions["organizations"]>("organizations")
    .update({ imageUrl, updatedAt: new Date().toISOString() })
    .eq("id", organizationId)
    .single();

  if (eventUpdateError) {
    throw new Error(eventUpdateError.message);
  }

  return eventUpdateData;
};

export function useUpdateOrganizationImage() {
  const { showToast } = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate, isLoading, data, error } = useMutation(updateOrganizationImageSupabase, {
    onSuccess: (updatedOrganization) => {
      console.log({ updatedOrganization });
      if (updatedOrganization) {
        queryClient.setQueryData(["myOrganization", updatedOrganization.id], updatedOrganization);
      }
      showToast(t("success.image-uploaded"));
    },
    onError: (error: Error) => {
      console.log(error);
      showToast(t("error.something-went-wrong"), "error");
      showToast(error.message, "error");
    },
  });

  const updateOrganization = ({ userId, organizationId, image }: UpdateOrganizationParams) => {
    mutate({ userId, organizationId, image });
  };

  return { updateOrganization, isLoading, data, error };
}
