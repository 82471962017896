import { createTheme, colors } from "@mui/material";
import typography from "./typography";

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    backgroundHome: Palette["primary"];
  }
  interface PaletteOptions {
    backgroundHome: PaletteOptions["primary"];
  }
}

const theme = createTheme({
  palette: {
    background: {
      default: "#F4F6F8",
      paper: colors.common.white,
    },
    backgroundHome: {
      main: "#fff2ea",
    },
    primary: {
      main: "#2d6aef",
      "50": "#D8E3FC",
      "100": "#C5D6FB",
      "200": "#9FBBF8",
      "300": "#79A0F5",
      "400": "#5385F2",
      "500": "#2D6AEF",
      "600": "#104ED4",
      "700": "#0C3BA0",
      "800": "#08276C",
      "900": "#041437",
    },
    secondary: {
      main: "#FCBB09",
      "50": "#FEEDBE",
      "100": "#FEE8AA",
      "200": "#FDDC82",
      "300": "#FDD15A",
      "400": "#FCC631",
      "500": "#FCBB09",
      "600": "#CA9502",
      "700": "#936C02",
      "800": "#5C4301",
      "900": "#241B00",
    },
    text: {
      primary: "#212131",
      secondary: "#8585AD",
    },
  },
  typography,
});

export default theme;
