import { EventType } from "../types";

interface EventTypes {
  [category: string]: EventType[];
}

const EXHIBITION: EventType[] = [
  "world-exhibition",
  "international-exhibition",
  "national-exhibition",
  "club-exhibition",
  "specialized-exhibition",
];
const COMPETITION: EventType[] = ["competition", "training-competition", "training", "seminar", "camp"];
const EXAM: EventType[] = ["IGP", "BH-VT", "PT", "PTT"];
const TRAINING: EventType[] = ["workshop", "webinar", "course", "conference"];

export const EVENT_TYPES: EventTypes = {
  agility: [...COMPETITION],
  obedience: [...COMPETITION],
  dogfrisbee: [...COMPETITION],
  puller: [...COMPETITION],
  exam: [...EXAM],
  exhibition: [...EXHIBITION],
  training: [...TRAINING],
};

export const EMAIL_REGEX =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export const USERNAME_REGEX = /^[a-zA-Z0-9]+([_-]?[a-zA-Z0-9])*$/;
