import isURL from "is-url";
import { CustomEditor, Element } from "slate";

import { wrapLink } from "../transforms";

export const withLinks = (editor: CustomEditor): CustomEditor => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element: Element) => {
    return element.type === "link" ? true : isInline(element);
  };

  editor.insertText = (text: string) => {
    if (text && isURL(text)) {
      wrapLink(editor, text, true);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data: DataTransfer) => {
    const text = data.getData("text/plain");

    if (text && isURL(text)) {
      wrapLink(editor, text, true);
    } else {
      insertData(data);
    }
  };

  return editor;
};
