import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import EventCard, { Event } from "../../components/event-card";
import PageViewLoader from "../../components/page-view-loader";
import SelectDropdown from "../../components/select-dropdown";
import PageContentWrapper from "../../components/page-content-wrapper";
import { EventType } from "../../types";
import { EVENT_TYPES } from "../../constants";
import { definitions } from "../../types/supabase";
import { PolandVoivodeship } from "../../types";

import { useEvents } from "./use-events";
import Error from "../../components/error";

const VOIVODESHIPS = Object.values(PolandVoivodeship);

const NoEventsWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  justifyContent: "center",
}));

const ToggleOnlineEvents = styled(Box)(({ theme }) => ({
  width: "100%",
  boxShadow: theme.shadows[3],
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up("sm")]: {
    maxWidth: 360,
  },
}));

const ToggleIsOnline = styled(FormControlLabel)({
  marginLeft: 0,
  display: "flex",
  justifyContent: "space-between",
});

interface CategoryParams {
  category: definitions["events"]["category"];
}

const EventsList = (): JSX.Element => {
  const { t } = useTranslation();
  const { category } = useParams() as CategoryParams;
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  // const queryTypes = searchParams.getAll("type") as EventType[];
  // const queryVoivodeship = searchParams.getAll("v") as PolandVoivodeship[];
  // const voivodeships: PolandVoivodeship[] = queryVoivodeship.length ? queryVoivodeship : VOIVODESHIPS;
  const [types, setTypes] = useState(EVENT_TYPES[category]);
  const [voivodeships, setVoivodeships] = useState(VOIVODESHIPS);
  const [isOnline, setIsOnline] = useState(true);
  const { events, isLoading, error } = useEvents({ types, category, voivodeships, isOnline });

  const onClickEvent = (eventSlug: string) => {
    navigate(`/wydarzenia/${category}/${eventSlug}`);
  };

  const handleSelectType = (type: string) => {
    if (types.includes(type as EventType)) {
      setSearchParams({ type: types.filter((t) => t !== type) });
      setTypes((types) => types.filter((t) => t !== type));
    } else {
      setSearchParams({ type: [...types, type as EventType] });
      setTypes((types) => [...types, type as EventType]);
    }
  };

  const toggleAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSearchParams({ type: EVENT_TYPES[category] });
      setTypes(EVENT_TYPES[category]);
    } else {
      setSearchParams({ type: [] });
      setTypes([]);
    }
  };

  const toggleAllVoivodeships = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSearchParams({ v: VOIVODESHIPS });
      setVoivodeships(VOIVODESHIPS);
    } else {
      setSearchParams({ v: [] });
      setVoivodeships([]);
    }
  };

  const toggleisOnline = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsOnline(event.target.checked);
  };

  const onSelectVoivodeship = (voivodeship: string) => {
    if (voivodeships.includes(voivodeship as PolandVoivodeship)) {
      setSearchParams({ v: voivodeships.filter((v) => v !== voivodeship) });
      setVoivodeships((voivodeships) => voivodeships.filter((v) => v !== voivodeship));
    } else {
      setSearchParams({ v: [...voivodeships, voivodeship as PolandVoivodeship] });
      setVoivodeships((voivodeships) => [...voivodeships, voivodeship as PolandVoivodeship]);
    }
  };

  if (error) return <Error error={error} />;

  return (
    <>
      <Helmet>
        <title>
          {t("events")} {t(`${category}`)} | Dogspace
        </title>
      </Helmet>
      <PageContentWrapper>
        <Grid container spacing={4}>
          <Grid container item xs={12} md={4} spacing={2} direction="column">
            <Grid item>
              <SelectDropdown
                onSelect={handleSelectType}
                toggleAll={toggleAll}
                options={EVENT_TYPES[category]}
                selected={types}
                title="events-list.category"
                isOpen
              />
            </Grid>
            <Grid item>
              <ToggleOnlineEvents>
                <ToggleIsOnline
                  value="start"
                  control={<Switch checked={isOnline} onChange={toggleisOnline} />}
                  label={t("events-list.online-events")}
                  labelPlacement="start"
                />
              </ToggleOnlineEvents>
            </Grid>
            <Grid item>
              <SelectDropdown
                onSelect={onSelectVoivodeship}
                toggleAll={toggleAllVoivodeships}
                options={VOIVODESHIPS}
                selected={voivodeships}
                title="events-list.voivodeship"
              />
            </Grid>
          </Grid>
          <View events={events} isLoading={isLoading} onClickEvent={onClickEvent} />
        </Grid>
      </PageContentWrapper>
    </>
  );
};

interface Props {
  events?: Event[] | null;
  isLoading: boolean;
  onClickEvent: (eventSlug: string) => void;
}

const View = ({ events, isLoading, onClickEvent }: Props) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <Grid item xs={12} md={8}>
        <PageViewLoader />
      </Grid>
    );
  }

  if (!events || !events.length) {
    return (
      <Grid item xs={8}>
        <NoEventsWrapper>{t("no-events-found")}</NoEventsWrapper>
      </Grid>
    );
  }

  return (
    <Grid item xs={12} md={8}>
      <Grid container spacing={2}>
        {events.map((event) => (
          <Grid item key={event.id} xs={12}>
            <EventCard event={event} onClick={() => onClickEvent(event.eventSlug)} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default EventsList;
