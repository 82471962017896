import { useMutation } from "react-query";

import supabase from "../../supabase";
import { useToast } from "../../components/toast";

interface DeleteEventParams {
  eventId: string;
}

const deleteEventSupabase = async ({ eventId }: DeleteEventParams) => {
  const { data, error } = await supabase.from("events").delete().match({ id: eventId });

  if (error) {
    throw new Error(error.message);
  }

  const { data: imagesList } = await supabase.storage.from("events").list(`images/${eventId}`);
  const filesToRemove = imagesList?.map((image) => `images/${eventId}/${image.name}`);
  if (filesToRemove) {
    await supabase.storage.from("events").remove(filesToRemove);
  }

  return data;
};

interface DeleteParams {
  onSuccess: () => void;
}

export function useDeleteEvent({ onSuccess }: DeleteParams) {
  const { showToast } = useToast();

  const { mutate, isLoading, data, error } = useMutation(deleteEventSupabase, {
    onSuccess,
    onError: (error: Error) => {
      showToast(error.message, "error");
    },
  });

  const deleteEvent = ({ eventId }: DeleteEventParams) => {
    mutate({ eventId });
  };

  return { deleteEvent, isLoading, data, error };
}
