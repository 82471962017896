import React from "react";
import { useTranslation } from "react-i18next";
import { styled, useTheme } from "@mui/material/styles";
import OCard from "@mui/material/Card";
import OCardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Computer, PinDrop } from "@mui/icons-material";
import { parseISO } from "date-fns";
import { Image } from "mui-image";

import { definitions } from "../../types/supabase";
import FALLBACK_IMAGE from "../../images/event-fallback.png";

import DateBox from "../date-box";

const LoaderCard = styled(OCard)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  width: "100%",
  height: 198,
  boxSizing: "border-box",
}));

const Card = styled(OCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  cursor: "pointer",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
  },
}));

const Details = styled("div")(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
  flexDirection: "column",
  alignSelf: "stretch",
  justifyContent: "space-between",
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  wordBreak: "break-word",
}));

const CardContent = styled(OCardContent)({
  flex: "1 0 auto",
  padding: 0,
});

const MobileHeaderWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const Bottom = styled("div")(({ theme }) => ({
  display: "grid",
  gap: theme.spacing(2),
  gridTemplateColumns: "auto 1fr",
  color: theme.palette.text.secondary,
  [theme.breakpoints.down("sm")]: {
    marginTop: theme.spacing(3),
  },
}));

const ImageWrapper = styled("div")(({ theme }) => ({
  width: 230,
  height: 150,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "auto",
  },
}));

export type Event = Pick<
  definitions["events"],
  "id" | "imageUrl" | "title" | "eventSlug" | "type" | "startDate" | "place" | "isOnline" | "category" | "isPublished"
>;

interface EventCardProps {
  event: Event;
  onClick: (eventSlug: string) => void;
}

const EventCard = ({ event, onClick }: EventCardProps): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
  const { imageUrl, title, type, startDate, place, isOnline } = event;
  const start = parseISO(startDate);

  const city = place?.address_components.find((component: google.maps.GeocoderAddressComponent) =>
    component.types.includes("locality"),
  )?.long_name;
  const country = place?.address_components.find((component: google.maps.GeocoderAddressComponent) =>
    component.types.includes("country"),
  )?.long_name;

  return (
    <Card onClick={onClick}>
      <ImageWrapper>
        <Image
          alt={title?.pl?.value}
          src={imageUrl || FALLBACK_IMAGE}
          duration={0}
          width={isMobile ? "100%" : 230}
          height={isMobile ? "auto" : 150}
          sx={{ borderRadius: 0.5, mb: isMobile ? 2 : 0 }}
        />
      </ImageWrapper>

      <Details>
        <CardContent>
          <MobileHeaderWrapper>
            <Typography variant="overline" color="textSecondary">
              {t(`${type}`, { count: 1 })}
            </Typography>
            {isMobile && <DateBox date={start} sx={{ alignSelf: "start", flexShrink: 0 }} />}
          </MobileHeaderWrapper>

          <Typography variant="h5">{title?.pl?.value}</Typography>
        </CardContent>

        <Bottom>
          {city || country ? (
            <>
              <PinDrop />
              <Typography variant="body1">{`${city ? `${city},` : ""} ${country}`}</Typography>
            </>
          ) : isOnline ? (
            <>
              <Computer />
              <Typography variant="body1">{t("event-form.is-online")}</Typography>
            </>
          ) : null}
        </Bottom>
      </Details>
      {!isMobile && <DateBox date={start} sx={{ alignSelf: "start", flexShrink: 0 }} />}
    </Card>
  );
};

export const EventCardLoader = (): JSX.Element => {
  return (
    <LoaderCard>
      <CircularProgress />
    </LoaderCard>
  );
};

export default EventCard;
