import React from "react";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup, { RadioGroupProps } from "@mui/material/RadioGroup";
import { Controller } from "react-hook-form";

interface Option {
  label: string;
  value: string | boolean;
  disabled?: boolean;
}

interface Props extends RadioGroupProps {
  name: string;
  control: any;
  label?: string;
  options: Option[];
  placeholder?: string;
  setValue?: any;
  rules?: any;
  defaultValue?: string | boolean;
}

const FormRadioGroup = ({ name, control, label, options, rules, defaultValue, ...props }: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <FormControl component="fieldset">
          {label && <FormLabel component="legend">{label}</FormLabel>}
          <RadioGroup value={value} onChange={onChange} {...props}>
            {options.map((option) => (
              <FormControlLabel
                key={option.label}
                value={option.value}
                label={option.label}
                control={<Radio />}
                disabled={!!option.disabled}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    />
  );
};

export default FormRadioGroup;
