import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { AppBar as OAppBar, AppBarProps, Box, Hidden, IconButton, Toolbar, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";

import logo from "../../images/logo-topbar.svg";

import { LINKS } from "../topbar";
import NavLink from "../nav-link";

const AppBar = styled(OAppBar)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.shadows[0],
  borderBottom: `solid 1px ${theme.palette.divider}`,
  zIndex: 1300,
}));

interface DashboardNavBarProps extends AppBarProps {
  onMobileNavOpen: () => void;
}

const DashboardNavbar = ({ onMobileNavOpen, ...rest }: DashboardNavBarProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <AppBar elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Box>
            <img src={logo} height={40} />
          </Box>
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <Hidden mdDown>
          {LINKS.map(({ name, href }) => (
            <NavLink to={href} key={name} sx={{ ml: 3 }}>
              <Typography variant="h6" color="inherit" sx={{ fontWeight: 400 }}>
                {t(`${name}`, { count: 2 })}
              </Typography>
            </NavLink>
          ))}
        </Hidden>
        <Hidden mdUp>
          <IconButton color="primary" onClick={onMobileNavOpen} size="large">
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardNavbar;
