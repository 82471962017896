import { useQuery } from "react-query";

import supabase from "../../supabase";
import { useAuth } from "../../auth-provider-supabase";
import { definitions } from "../../types/supabase";

const getMyEventsSupabase = async ({ userId }: { userId?: string }) => {
  const { data, error } = await supabase.from<definitions["events"]>("events").select().eq("userId", userId);
  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export function useMyEvents() {
  const { currentUser } = useAuth();
  const { isLoading, data, error, isError, refetch } = useQuery(
    "myEvents",
    () => getMyEventsSupabase({ userId: currentUser?.id }),
    { enabled: !!currentUser },
  );

  return { myEvents: data, isLoading, error, isError, refetch };
}
