import React, { ReactNode } from "react";
import { Typography } from "@mui/material/";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "40px 1fr",
  alignItems: "center",
  gap: theme.spacing(1),
  margin: `${theme.spacing(1)} 0px`,
}));

interface Props {
  icon: ReactNode;
  children: string;
}

const ItemWithIcon = ({ icon, children }: Props): JSX.Element => {
  return (
    <Wrapper>
      {icon}
      <Typography color="textSecondary">{children}</Typography>
    </Wrapper>
  );
};

export default ItemWithIcon;
