import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useToast } from "../../components/toast";
import supabase from "../../supabase";
import { definitions } from "../../types/supabase";
import { makeId, makeSlug } from "../../utils";

interface UpdateEventParams {
  event: definitions["events"];
  image: File | null;
}

const updateEventSupabase = async ({ event, image }: UpdateEventParams) => {
  let eventSlug = makeSlug(event.title.pl.value);
  const { data: existingSlug } = await supabase.from("events").select().eq("eventSlug", eventSlug).single();

  if (existingSlug && existingSlug.id !== event.id) {
    const id = makeId(4);
    eventSlug = `${eventSlug}-${id}`;
  }

  const voivodeship = event.place?.address_components.find((component: google.maps.GeocoderAddressComponent) =>
    component.types.includes("administrative_area_level_1"),
  )?.long_name;

  let uploadData = null;
  if (image) {
    const { data, error: uploadError } = await supabase.storage
      .from("events")
      .upload(`images/${event.id}/${image.name}`, image);

    if (uploadError) {
      throw new Error(uploadError.message);
    }

    uploadData = data;
  }

  const imageUrl =
    image && uploadData ? `${process.env.REACT_APP_STORAGE_PUBLIC_BASE_URL}/${uploadData.Key}` : event.imageUrl;

  const { data: eventUpdateData, error: eventUpdateError } = await supabase
    .from<definitions["events"]>("events")
    .update({ ...event, eventSlug, imageUrl, voivodeship, updatedAt: new Date().toISOString() })
    .eq("id", event.id)
    .single();

  if (eventUpdateError) {
    throw new Error(eventUpdateError.message);
  }

  return eventUpdateData;
};

export function useUpdateEvent() {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate, isLoading, data, error } = useMutation(updateEventSupabase, {
    onSuccess: (updatedEvent) => {
      if (updatedEvent) {
        queryClient.setQueryData(["myEventEdit", updatedEvent.id], updatedEvent);
      }
      showToast(t("success.event-updated"));
      navigate("..");
    },
    onError: (error: Error) => {
      showToast(t("error.something-went-wrong"), "error");
      showToast(error.message, "error");
    },
  });

  const updateEvent = ({ event, image }: UpdateEventParams) => {
    mutate({ event, image });
  };

  return { updateEvent, isLoading, data, error };
}
