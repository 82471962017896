import slugify from "slugify";

interface SlugifyOptions {
  replacement?: string;
  remove?: RegExp;
  lower?: boolean;
  strict?: boolean;
  locale?: string;
  trim?: boolean;
}

export const makeSlug = (text: string, options?: SlugifyOptions) => {
  return slugify(text, { lower: true, remove: /[*+~.()'"!:@]/g, ...options });
};
